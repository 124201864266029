import ClassNames from "classnames";
import { createElement, FunctionComponent, useEffect } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { createModuleSections } from "../selectors/modules.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  AllModulesByType,
  BaseModuleProps,
  GeneratorStoreState,
  Language,
  Module,
  Modules,
  ModuleSection,
  ModuleType,
  TranslatedModule,
  TranslatedPage,
  WindowState,
} from "../types/index.js";
import {
  getUsercentricsConsent,
  onUsercentricsConsent,
} from "../utils/usercentrics.js";
import {
  checkIsHomePage,
  checkIsString,
  getFallbackLanguage,
  getSubModulesIds,
  getTranslatedModule,
  setCookie,
} from "../utils/utils.js";
import ContainerQueries from "./ContainerQueries.js";
import SiteInner from "./SiteInner.js";

const setPromotionCookie = (searchParams: URLSearchParams) => {
  const gclid = searchParams.get("gclid");
  const utm_source = gclid ? "google.com" : searchParams.get("utm_source");
  const utm_medium = gclid ? "cpc" : searchParams.get("utm_medium");
  const utm_campaign = gclid ?? searchParams.get("utm_campaign");

  utm_source &&
    utm_medium &&
    setCookie(
      "bs_widget_promotion",
      [utm_medium, utm_source, utm_campaign].filter(Boolean).join(","),
      30,
    );
};

interface Props {
  page: TranslatedPage;
  modulesByType: Partial<AllModulesByType>;
}

interface StateProps {
  moduleSections: ModuleSection[];
  languageId: Language;
  fallbackLanguageId: Language | undefined;
  isHomePage: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const Generator: FunctionComponent<Props & ReduxProps> = ({
  page: { id: pageId },
  moduleSections,
  languageId,
  fallbackLanguageId,
  isHomePage,
  modulesByType,
}) => {
  useEffect(() => {
    const currentWindow = window as unknown as WindowState;
    currentWindow.BookingSüdtirolTrackingConsent = false;

    const handleUsercentricsConsent = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const isConsented = await getUsercentricsConsent("Promotion");
      const onConsentGiven = () => {
        currentWindow.BookingSüdtirolTrackingConsent = true;
        setPromotionCookie(searchParams);
      };

      isConsented
        ? onConsentGiven()
        : onUsercentricsConsent("Promotion").then(onConsentGiven);

      // Fire a Google Tag Manager (GTM) event after the cookie is set
      // (or not set due to empty values).
      // This is necessary for GTM tags which need to read the value
      // of the bs_widget_promotion cookie, e.g. the ReGuest Messenger Widget.
      (window as unknown as WindowState).dataLayer?.push({
        event: "BsPromotionSet",
      });
    };

    handleUsercentricsConsent();
  }, []);

  return (
    <ContainerQueries
      className={ClassNames("Site", { "Site--is-home": isHomePage })}
      useViewportWidth={true}
    >
      {(queries) => (
        <SiteInner isPreview={false}>
          {moduleSections.map((moduleSection) => {
            const translatedModules = moduleSection.items.map((currentModule) =>
              getTranslatedModule(
                currentModule,
                languageId,
                fallbackLanguageId,
              ),
            );

            const firstIndex = translatedModules.findIndex(
              checkModuleHasTitleOrSubtitle,
            );

            return translatedModules.map((translatedModule, index) => {
              if (!translatedModule.translation.exists) return null;
              const pageModule = modulesByType[translatedModule.type];

              return !pageModule
                ? null
                : createElement<BaseModuleProps>(pageModule, {
                    key: translatedModule.id + languageId,
                    translatedModule,
                    isPreview: false,
                    queries,
                    pageId,
                    isActive: false,
                    isFirstOnPage: firstIndex === index,
                    activeModuleId: undefined,
                  });
            });
          })}
        </SiteInner>
      )}
    </ContainerQueries>
  );
};

/**
 * Check if module types that make no sense
 * without submodules have at least one submodule
 */
const checkHasSubmodules = (
  modules: Modules,
  pageId: string,
  { id, type }: Module,
): boolean => {
  const moduleTypes: ModuleType[] = [
    "HighlightsModule",
    "ImageGalleryModule",
    "ImagesModule",
    "WeatherWebcamModule",
  ];

  if (!moduleTypes.includes(type)) return true;

  const subModuleIds = getSubModulesIds({
    byParentId: modules.byParentId,
    pageId,
    parentId: id,
    moduleType: undefined,
  });

  return !!subModuleIds.length;
};

/**
 * Filter out modules which must be hidden on the published website
 */
const filterModuleSections = ({
  modules,
  moduleSections,
  pageId,
}: {
  modules: Modules;
  moduleSections: ModuleSection[];
  pageId: string;
}): ModuleSection[] =>
  moduleSections.map((moduleSection) => {
    const filteredItems = moduleSection.items.filter((currentModule) =>
      checkHasSubmodules(modules, pageId, currentModule),
    );

    return { ...moduleSection, items: filteredItems };
  });

const mapStateToProps: MapStateToProps<
  StateProps,
  Props,
  GeneratorStoreState
> = ({ modules, sites, pages }, { page }): StateProps => {
  const { languageId } = page.translation;
  const site = getActiveSite(sites);
  const moduleSections = createModuleSections({
    modules,
    pageId: page.id,
    popUpModuleIds: [page.popUpModuleId, site.popUpModuleId]
      .filter(checkIsString)
      .slice(0, 1),
    quickEnquiryModuleId: modules.bySiteModuleType.QuickEnquiryModule?.[0],
  });
  const fallbackLanguageId = getFallbackLanguage(site, languageId);

  return {
    moduleSections: filterModuleSections({
      modules,
      moduleSections,
      pageId: page.id,
    }),
    languageId,
    fallbackLanguageId,
    isHomePage: checkIsHomePage(pages, page.id),
  };
};

const checkModuleHasTitleOrSubtitle = (
  translatedModule: TranslatedModule,
): boolean => {
  const {
    translation: { settings },
  } = translatedModule as TranslatedModule<{
    global: {};
    language: { title?: string; subtitle?: string };
  }>;

  return Boolean(settings.title || settings.subtitle);
};

const connector = connect(mapStateToProps);

export default connector(Generator);
