import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { Language, LanguageMenuItem } from "../types/index.js";
import SmartLink from "./SmartLink.js";

interface Props {
  items: LanguageMenuItem[];
  isPreview: boolean;
  languageId: Language;
}

const LanguageMenuList: FunctionComponent<Props> = ({
  items,
  isPreview,
  languageId,
}) => (
  <ul className="LanguageMenu__List">
    {items.map(({ children, href, hrefLang, rel }) => (
      <li
        className={ClassNames("LanguageMenu__ListItem", {
          "LanguageMenu__ListItem--active": hrefLang === languageId,
        })}
        key={hrefLang}
      >
        <SmartLink
          isPreview={isPreview}
          anchorAttrs={{ rel, hrefLang, href, className: "LanguageMenu__Item" }}
        >
          {children}
        </SmartLink>
      </li>
    ))}
  </ul>
);

export default LanguageMenuList;
