import { AllActions } from "../actions/index.js";
import { Special } from "../types/index.js";

const reducer = (state: Special[] = [], action: AllActions): Special[] => {
  switch (action.type) {
    case "GET_SPECIALS_SUCCESS":
      return action.specials;

    default:
      return state;
  }
};

export default reducer;
