import { FunctionComponent } from "react";
import { AspectRatio, IconName } from "../types/index.js";
import FormFieldRadios from "./FormFieldRadios.js";

interface Props {
  currentFormValue: AspectRatio;
  onChange: (value: AspectRatio) => void;
  allowedAspectRatios?: AspectRatio[];
}

interface AspectRatioSelect {
  value: AspectRatio;
  title: string;
  iconGlyphs: IconName;
  label: string;
}

const aspectRatios: AspectRatioSelect[] = [
  {
    value: 1.3333,
    title: "4:3 (Querformat)",
    iconGlyphs: "aspect-ratio-4-3",
    label: "4:3",
  },
  {
    value: 1.7778,
    title: "16:9 (Querformat)",
    iconGlyphs: "aspect-ratio-16-9",
    label: "16:9",
  },
  {
    value: 2.6667,
    title: "8:3 (Querformat)",
    iconGlyphs: "aspect-ratio-7-5",
    label: "8:3",
  },
  {
    value: 4,
    title: "4:1 (Querformat)",
    iconGlyphs: "aspect-ratio-7-5",
    label: "4:1",
  },

  {
    value: 0.75,
    title: "3:4 (Hochformat)",
    iconGlyphs: "aspect-ratio-3-4",
    label: "3:4",
  },
  {
    value: 0.5625,
    title: "9:16 (Hochformat)",
    iconGlyphs: "aspect-ratio-9-16",
    label: "9:16",
  },
  {
    value: 0.375,
    title: "3:8 (Hochformat)",
    iconGlyphs: "aspect-ratio-5-7",
    label: "3:8",
  },
  { value: 1, title: "1:1 (quadratisch)", iconGlyphs: "square", label: "1:1" },
];

const getSelectableAspectRatios = (
  allowedAspectRatios: AspectRatio[],
  aspectRatios: AspectRatioSelect[],
) => aspectRatios.filter(({ value }) => allowedAspectRatios.includes(value));

const AspectRatioSelection: FunctionComponent<Props> = ({
  currentFormValue,
  onChange,
  allowedAspectRatios,
}) => (
  <FormFieldRadios<AspectRatio>
    radios={
      allowedAspectRatios
        ? getSelectableAspectRatios(allowedAspectRatios, aspectRatios)
        : aspectRatios
    }
    denseLayout={true}
    currentFormValue={currentFormValue}
    onChange={(value) => {
      onChange(value);
    }}
    fieldLabel="Medien-Seitenverhältnis"
    expandButtons={true}
  />
);

export default AspectRatioSelection;
