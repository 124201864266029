import { combineReducers } from "redux";
import { GeneratorStoreState, StoreState } from "../types/index.js";
import accommodation from "./accommodation.js";
import alerts from "./alerts.js";
import colorSchemes from "./colorSchemes.js";
import fonts from "./fonts.js";
import issues from "./issues.js";
import languages from "./languages.js";
import legalNav from "./legalNav.js";
import linkSelect from "./linkSelect.js";
import loadStates from "./loadStates.js";
import login from "./login.js";
import logos from "./logos.js";
import mediaLibrary from "./mediaLibrary.js";
import modules from "./modules/index.js";
import pages from "./pages.js";
import partners from "./partners.js";
import prices from "./prices.js";
import publish from "./publish.js";
import revisions from "./revisions.js";
import richEditor from "./richEditor.js";
import rooms from "./rooms.js";
import seo from "./seo/index.js";
import sites from "./sites.js";
import specials from "./specials.js";
import user from "./user.js";

export const adminReducer = combineReducers<StoreState>({
  accommodation,
  alerts,
  colorSchemes,
  fonts,
  languages,
  legalNav,
  linkSelect,
  login,
  partners,
  logos,
  mediaLibrary,
  modules,
  pages,
  publish,
  richEditor,
  rooms,
  prices,
  sites,
  specials,
  user,
  loadStates,
  revisions,
  seo,
  issues,
});

export const generatorReducer = combineReducers<GeneratorStoreState>({
  accommodation,
  colorSchemes,
  fonts,
  legalNav,
  partners,
  logos,
  mediaLibrary,
  modules,
  pages,
  richEditor,
  sites,
  loadStates,
});
