import { FunctionComponent } from "react";
import { ColorScheme as ColorSchemeType } from "../types/index.js";
import Icon from "./Icon.js";

interface Props {
  id: string;
  scheme: ColorSchemeType;
  isActive: boolean;
  onEdit: (id: string) => void;
  onDelete?: (id: string) => void;
  onSelection: (id: string) => void;
  onDuplicate: (id: string) => void;
  isDefaultScheme: boolean;
}

const ColorScheme: FunctionComponent<Props> = ({
  scheme,
  onDelete,
  onDuplicate,
  onEdit,
  onSelection,
  id,
  isActive,
  isDefaultScheme,
}) => {
  const checkButtonContent = isDefaultScheme ? "S" : <Icon glyph="check" />;
  const checkButtonTitle = isDefaultScheme ? "Standard-Farbschema" : undefined;

  const colors = [
    scheme.main.background,
    scheme.main.separator,
    scheme.main.text,
    scheme.main.title,
    scheme.primary.background,
    scheme.primary.text,
    scheme.secondary.background,
    scheme.secondary.text,
  ];

  return (
    <div className="ColorScheme">
      <div className="ColorScheme__Colors" onClick={() => onSelection(id)}>
        {colors.map((color, index) => (
          <div
            key={index}
            className="ColorScheme__Color"
            style={{ backgroundColor: color }}
          />
        ))}
      </div>
      <div className="ColorScheme__Controls">
        {onDelete && (
          <button type="button" className="Btn" onClick={() => onDelete(id)}>
            <Icon glyph="delete" />
          </button>
        )}
        <button type="button" className="Btn" onClick={() => onDuplicate(id)}>
          <Icon glyph="duplicate" />
        </button>
        <button type="button" className="Btn" onClick={() => onEdit(id)}>
          <Icon glyph="edit" />
        </button>
        {!isActive && (
          <button
            type="button"
            title={checkButtonTitle}
            className="Btn"
            onClick={() => onSelection(id)}
          >
            {checkButtonContent}
          </button>
        )}
        {isActive && (
          <button
            type="button"
            title={checkButtonTitle}
            className="Btn Btn--active"
          >
            {checkButtonContent}
          </button>
        )}
      </div>
    </div>
  );
};

export default ColorScheme;
