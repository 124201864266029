import { FunctionComponent, ReactNode, useEffect, useRef } from "react";

interface Props {
  onLoad: (isLazyloaded: true) => void;
  className?: string;
  children: ReactNode;
}

const LazyloadWrapper: FunctionComponent<Props> = ({
  children,
  onLoad,
  className,
}) => {
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const callback = () => {
      onLoad(true);
    };

    elRef.current?.addEventListener("lazybeforeunveil", callback);
    // The lazyload class is added here instead of in the
    // className attribute to make sure the lazybeforeunveil
    // event is triggered.
    elRef.current?.classList.add("lazyload");

    return () => {
      elRef.current?.removeEventListener("lazybeforeunveil", callback);
    };
  }, []);

  return (
    <div ref={elRef} className={className}>
      {children}
    </div>
  );
};

export default LazyloadWrapper;
