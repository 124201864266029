import { ResizeObserver } from "@juggle/resize-observer";
import ClassNames from "classnames";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { style } from "typestyle";
import translations from "../i18n/index.js";
import { Language } from "../types/index.js";
import { getTranslations } from "../utils/utils.js";
import Icon from "./Icon.js";

interface Props {
  backgroundColor: string;
  languageId: Language;
  isEditing: boolean;
  collapsedLinesCount: number;
  children: ReactNode;
}

const getLineHeightEm = (linesCount: number) => linesCount * 1.8 + "em";

const ReadMoreCollapse: FunctionComponent<Props> = ({
  children,
  backgroundColor,
  languageId,
  collapsedLinesCount,
  isEditing,
}) => {
  const collapsedHeight = getLineHeightEm(collapsedLinesCount);
  const [state, setState] = useState<{ isOpen: boolean; height: string }>({
    isOpen: false,
    height: collapsedHeight,
  });
  const [moreButtonShown, setMoreButtonShown] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);
  const collapseRef = useRef<HTMLDivElement>(null);
  const measureRef = useRef<HTMLDivElement>(null);
  const i18n = getTranslations(languageId, translations);
  const closeTriggeredRef = useRef(false);

  useEffect(() => {
    setState({ isOpen: false, height: getLineHeightEm(collapsedLinesCount) });
  }, [collapsedLinesCount]);

  useEffect(() => {
    const onTransitionEnd = () => {
      setState(({ isOpen, height }) => ({
        isOpen,
        height: isOpen ? "" : height,
      }));
    };

    collapseRef.current?.addEventListener("transitionend", onTransitionEnd, {
      passive: true,
    });

    const onResize = () => {
      const contentHeight = contentRef.current?.scrollHeight;
      const collapseHeight = measureRef.current?.scrollHeight;
      if (contentHeight === undefined || collapseHeight === undefined) return;

      setMoreButtonShown(contentHeight >= collapseHeight);
    };

    const observer = new ResizeObserver(onResize);
    onResize();

    contentRef.current && observer.observe(contentRef.current);

    return () => {
      collapseRef.current?.removeEventListener(
        "transitionend",
        onTransitionEnd,
      );
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!closeTriggeredRef.current) return;

    closeTriggeredRef.current = false;

    setTimeout(() => {
      setState({ isOpen: false, height: getLineHeightEm(collapsedLinesCount) });
    }, 100);
  }, [state.isOpen, collapsedLinesCount]);

  return (
    <div className="ReadMoreCollapse">
      <div
        ref={measureRef}
        className="ReadMoreCollapse__Measure"
        style={{ height: getLineHeightEm(collapsedLinesCount) }}
      ></div>
      <div
        style={{
          height: moreButtonShown && !isEditing ? state.height : undefined,
        }}
        ref={collapseRef}
        className={ClassNames(
          "ReadMoreCollapse__Inner",
          {
            "ReadMoreCollapse__Inner--collapsed":
              moreButtonShown && !isEditing && !state.isOpen,
          },
          style({
            $nest: {
              "&::after": {
                // The second color needs to be rgba instead of "transparent"
                // due to a bug in Safari.
                background: `linear-gradient(
                  0deg, ${backgroundColor} 0%, rgba(255, 255, 255, 0) 3em
                )`,
              },
            },
          }),
        )}
      >
        <div ref={contentRef} className="ReadMoreCollapse__Content">
          {children}
        </div>
      </div>
      {moreButtonShown && !isEditing && (
        <div className="ReadMoreCollapse__BtnContainer">
          <button
            className="ReadMoreCollapse__Btn Btn--bare"
            title={i18n.more}
            type="button"
            onClick={(event) => {
              // Remove focus immediately to prevent unwanted
              // keeping of the scroll position in browsers like Chrome
              event.currentTarget.blur();

              const maxHeight =
                contentRef.current?.getBoundingClientRect().height + "px";

              setState(({ isOpen }) => {
                if (isOpen) {
                  closeTriggeredRef.current = true;
                }

                return { isOpen: !isOpen, height: maxHeight };
              });
            }}
          >
            <Icon
              className="ReadMoreCollapse__Icon"
              glyph={state.isOpen ? "arrow-up" : "arrow-down"}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default ReadMoreCollapse;
