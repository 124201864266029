import ClassNames from "classnames";
import { FunctionComponent } from "react";

interface Props {
  className: string;
  isMenuOpen: boolean;
  onClick: () => void;
}

const HamburgerButton: FunctionComponent<Props> = ({
  className,
  isMenuOpen,
  onClick,
}) => (
  <button
    type="button"
    className={ClassNames("HamburgerButton", className, {
      "HamburgerButton--open": isMenuOpen,
    })}
    onClick={onClick}
  >
    <span className="HamburgerButton__Icon">
      <span className="HamburgerButton__Line HamburgerButton__Line1"></span>
      <span className="HamburgerButton__Line HamburgerButton__Line2"></span>
      <span className="HamburgerButton__Line HamburgerButton__Line3"></span>
    </span>
  </button>
);

export default HamburgerButton;
