import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { PartnerLogoSize, TranslatedPartnerLogo } from "../types/index.js";
import LazyImage from "./LazyImage.js";

interface Props {
  partnerLogo: TranslatedPartnerLogo;
  size: PartnerLogoSize;
  className?: string;
}

const PartnerLogo: FunctionComponent<Props> = ({
  partnerLogo,
  size,
  className,
}) => {
  const {
    translation: { url: logoURL },
    partner: {
      translation: { origin, url, name },
    },
  } = partnerLogo;

  const href = origin + url;

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener"
      className={ClassNames("PartnerLogo", `PartnerLogo--${size}`, className)}
    >
      <LazyImage src={logoURL} alt={name} />
    </a>
  );
};

export default PartnerLogo;
