import { APIPartner } from "../../server/types/index.js";
import { AllActions } from "../actions/index.js";
import { Logo, LogosState } from "../types/index.js";

const initialState: LogosState = {};

const getPartnersSuccess = (partners: APIPartner[]): LogosState => {
  return partners.reduce<LogosState>((newState, partner) => {
    return partner.logos.reduce<LogosState>((newState, logo) => {
      const newLogo: Logo = { ...logo, partnerId: partner.id };

      return { ...newState, [logo.id]: newLogo };
    }, newState);
  }, {});
};

const reducer = (
  state: LogosState = initialState,
  action: AllActions,
): LogosState => {
  switch (action.type) {
    case "GET_PARTNERS_SUCCESS":
      return getPartnersSuccess(action.partners);

    default:
      return state;
  }
};

export default reducer;
