import { combineReducers, Reducer } from "redux";
import { AllActions } from "../../actions/index.js";
import { Modules } from "../../types/index.js";
import actionModules from "./actionModules.js";
import byId from "./byId.js";
import byPageId from "./byPageId.js";
import byParentId from "./byParentId.js";
import bySiteModuleType from "./bySiteModuleType.js";
import modulesReducer from "./modules.js";

export const combined = combineReducers<Modules>({
  byId,
  byParentId,
  byPageId,
  actionModules,
  bySiteModuleType,
});

const reducer: Reducer<Modules, AllActions> = (state, action) => {
  const stateFromAll = modulesReducer(state, action);
  return combined(stateFromAll, action);
};

export default reducer;
