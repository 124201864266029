import { FunctionComponent, useEffect, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { injectScript } from "../../actions/LoadStates.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  GuestnetWidgetModuleSettings,
  StoreState,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getSupportedLanguage,
} from "../../utils/utils.js";
import LazyloadWrapper from "../LazyloadWrapper.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "gn-weekprogram": {
        "data-base-url": string;
        "data-color": string;
        "data-lang": string;
      };
    }
  }
}

type Props = BaseModuleProps<GuestnetWidgetModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const GuestnetWidgetModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign, baseUrl },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  injectScript,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);

  useEffect(() => {
    isLazyloaded &&
      baseUrl &&
      // TODO: setTimeout is only a temporary workaround
      // to prevent the widget from messing with the
      // colors provided by TypeStyle. Find another
      // solution for this.
      setTimeout(() => {
        injectScript(
          "https://s3.eu-central-1.amazonaws.com/guestnet.info/widgets/weekprogram-1.1.js",
        );
      }, 2000);
  }, [isLazyloaded, baseUrl]);

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="GuestnetWidgetModule"
      colors={{ background: scheme.main.background, color: scheme.main.text }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div className="Module__Wrapper GuestnetWidgetModule__Container">
          {baseUrl && (
            <gn-weekprogram
              data-base-url={baseUrl}
              data-color={scheme.main.separator}
              data-lang={getSupportedLanguage(languageId, ["de", "it", "en"])}
            ></gn-weekprogram>
          )}
          {!baseUrl && (
            <div className="GuestnetWidgetModule__Empty">Guestnet</div>
          )}
        </div>
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

const mapDispatchToProps = { injectScript };

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule },
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule,
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(GuestnetWidgetModule);
