import { FunctionComponent } from "react";
import { connect } from "react-redux";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ButtonModuleSettings,
  ColorScheme,
  StoreState,
} from "../../types/index.js";
import { resolveButtonLink } from "../../utils/utils.js";
import Button from "../Button.js";

type Props = Pick<
  BaseModuleProps<ButtonModuleSettings>,
  "translatedModule" | "isPreview"
> & { scheme: ColorScheme; className?: string };

interface StateProps {
  anchorAttrs: React.AnchorHTMLAttributes<HTMLAnchorElement>;
}

const ButtonModule: FunctionComponent<Props & StateProps> = ({
  translatedModule: { settings, translation },
  isPreview,
  anchorAttrs,
  scheme,
  className,
}) => {
  if (!translation.exists) return null;

  return (
    <Button
      className={className}
      scheme={scheme}
      isPreview={isPreview}
      {...settings}
      anchorAttrs={anchorAttrs}
    >
      {translation.settings.title}
    </Button>
  );
};

const mapStateToProps = (
  { pages, sites }: StoreState,
  { translatedModule, isPreview }: Props,
): StateProps => {
  const site = getActiveSite(sites);
  const anchorAttrs = resolveButtonLink({
    pages,
    isPreview,
    site,
    translatedModule,
  });

  return { anchorAttrs };
};

export default connect(mapStateToProps)(ButtonModule);
