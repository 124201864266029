import { Language } from "../../server/types/index.js";
import { ControlItem as ControlItemType } from "../types/index.js";
import ControlItem from "./ControlItem.js";
import SortableList, { OnDrag } from "./SortableList.js";

interface Props<Item extends ControlItemType> {
  items: Item[];
  onDragEnd: OnDrag<Item>;
  onDragOver?: OnDrag<Item>;
  onChange: (id: Item["id"], index: number) => void;
  onTranslate?: (id: Item["id"], sourceLanguageId: Language) => void;
  onRemove?: (id: Item["id"], index: number) => void;
}

const ControlItemsList = <Item extends ControlItemType>({
  items,
  onDragEnd,
  onDragOver,
  onChange,
  onTranslate,
  onRemove,
}: Props<Item>) => (
  <ul className="ControlItemsList">
    <SortableList items={items} onDragEnd={onDragEnd} onDragOver={onDragOver}>
      {items.map((item, index) => (
        <ControlItem
          key={item.id}
          hasMultiple={items.length > 1}
          index={index}
          item={item}
          onChange={onChange}
          onRemove={onRemove}
          onTranslate={onTranslate}
        />
      ))}
    </SortableList>
  </ul>
);

export default ControlItemsList;
