import ClassNames from "classnames";
import { FunctionComponent, ReactNode, RefObject } from "react";
import { style } from "typestyle";

interface Props {
  id: string;
  title: string | undefined;
  subtitle: string | undefined;
  colors: { background?: string; color?: string } | undefined;
  className: string;
  innerRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
}

const getModuleTag = ({
  title,
  subtitle,
}: {
  title: string | undefined;
  subtitle: string | undefined;
}) => (title || subtitle ? "section" : "div");

const ModuleWithHeadings: FunctionComponent<Props> = ({
  children,
  title,
  subtitle,
  id,
  colors,
  className,
  innerRef,
}) => {
  const TagName = getModuleTag({ title, subtitle });
  return (
    <TagName
      id={id}
      ref={innerRef}
      className={ClassNames(
        "Module",
        className,
        style({ backgroundColor: colors?.background, color: colors?.color }),
      )}
    >
      {children}
    </TagName>
  );
};

export default ModuleWithHeadings;
