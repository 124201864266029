import { FunctionComponent } from "react";
import { BaseModuleProps, OfferingsModuleSettings } from "../../types/index.js";
import OfferingsModule from "./OfferingsModule.js";

interface Props extends BaseModuleProps<OfferingsModuleSettings> {}

const RoomsModule: FunctionComponent<Props> = (props) => {
  const moduleId = props.translatedModule.id;
  return (
    <OfferingsModule
      {...props}
      type="Rooms"
      urlHashPrefix={`#${moduleId}-rooms-`}
    />
  );
};

export default RoomsModule;
