import { Reducer } from "redux";
import { APIModule } from "../../../server/types/index.js";
import { AllActions } from "../../actions/index.js";
import {
  ActionModule,
  ActionModules,
  Language,
  ModuleType,
} from "../../types/index.js";
import { keys } from "../../utils/utils.js";

export const initialState: ActionModules = {};

const sortOrder: { [K in ModuleType]?: number } = {
  BookingModule: 1,
  EnquiryModule: 2,
  RoomsModule: 3,
  SpecialsModule: 4,
};

const addActionModule = (
  state: ActionModules,
  languageIds: Language[],
  actionModule: ActionModule,
): ActionModules => {
  if (!sortOrder[actionModule.type]) return state;

  const newState = languageIds.reduce<ActionModules>((carry, languageId) => {
    const newActionModulesByLanguage = [
      ...(carry[languageId] || []),
      actionModule,
    ];
    const sorted = sortActionModules(newActionModulesByLanguage);
    const updatedActionModules = { ...carry, [languageId]: sorted };

    return updatedActionModules;
  }, state);

  return newState;
};

const sortActionModules = (actionModules: ActionModule[]) => {
  return [...actionModules].sort(
    ({ type: moduleType1 }, { type: moduleType2 }) => {
      const v1 = sortOrder[moduleType1] || 5;
      const v2 = sortOrder[moduleType2] || 5;

      if (v1 < v2) return -1;
      else if (v1 === v2) return 0;
      return 1;
    },
  );
};

const getActionModules = (modules: APIModule[]): ActionModules => {
  const actionModules = modules.reduce<ActionModules>(
    (carry, { id, pageId, type, translations }) => {
      if (!pageId) return carry;

      const actionModule: ActionModule = { id, pageId, type };

      const updatedState = keys(translations).reduce<ActionModules>(
        (innerCarry, languageId) => {
          const current = [...(innerCarry[languageId] || []), actionModule];
          return { ...innerCarry, [languageId]: current };
        },
        carry,
      );

      return updatedState;
    },
    {},
  );

  const sortedState = keys(actionModules).reduce<ActionModules>(
    (carry, languageId) => {
      const current = carry[languageId] || [];
      const sorted = sortActionModules(current);
      return { ...carry, [languageId]: sorted };
    },
    actionModules,
  );

  return sortedState;
};

const deleteActionModule = (
  state: ActionModules,
  moduleId: string,
  deleteAllTranslations: boolean,
  languageId: Language,
): ActionModules => {
  if (deleteAllTranslations) {
    return keys(state).reduce<ActionModules>((carry, languageId) => {
      const current = carry[languageId] || [];
      const filtered = current.filter(({ id }) => moduleId !== id);
      return { ...carry, [languageId]: filtered };
    }, state);
  }

  const filtered = (state[languageId] || []).filter(
    ({ id }) => moduleId !== id,
  );

  return { ...state, [languageId]: filtered };
};

const translateActionModule = (
  state: ActionModules,
  {
    moduleId,
    pageId,
    languageId,
    sourceLanguageId,
  }: {
    moduleId: string;
    pageId: string;
    languageId: Language;
    sourceLanguageId: Language;
  },
): ActionModules => {
  const isAlreadyInActionModules = (state[languageId] || []).some(
    ({ id }) => id === moduleId,
  );
  if (isAlreadyInActionModules) return state;

  const sourceActionModule = (state[sourceLanguageId] || []).find(
    ({ id }) => id === moduleId,
  );
  if (!sourceActionModule) return state;

  const newActionModule: ActionModule = {
    id: moduleId,
    pageId,
    type: sourceActionModule.type,
  };
  const byCurrentLanguage = [...(state[languageId] || []), newActionModule];
  const sorted = sortActionModules(byCurrentLanguage);

  return { ...state, [languageId]: sorted };
};

const reducer: Reducer<ActionModules, AllActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case "POST_MODULE_START":
      return action.pageId === null
        ? state
        : addActionModule(state, action.languageIds, {
            id: action.moduleId,
            pageId: action.pageId,
            type: action.moduleType,
          });

    case "GET_ACTION_MODULES_SUCCESS":
      return getActionModules(action.modules);

    case "DELETE_MODULE_TRANSLATION_START":
      return deleteActionModule(
        state,
        action.moduleId,
        action.deleteAllTranslations,
        action.languageId,
      );

    case "TRANSLATE_MODULE_START":
      return action.pageId === null
        ? state
        : translateActionModule(state, {
            moduleId: action.moduleId,
            pageId: action.pageId,
            languageId: action.languageId,
            sourceLanguageId: action.sourceLanguageId,
          });

    default:
      return state;
  }
};

export default reducer;
