import { AllActions } from "../actions/index.js";
import { LoadStates, LoadStatus } from "../types/index.js";

const initialState: LoadStates = {
  accommodation: {},
  colorSchemes: "unloaded",
  partners: "unloaded",
  mediaLibrary: {
    pictures: "unloaded",
    groups: "unloaded",
    picturesByGroupId: {},
  },
  pages: "unloaded",
  rooms: "unloaded",
  prices: "unloaded",
  site: "unloaded",
  specials: "unloaded",
  revisions: "unloaded",
  actionModules: "unloaded",
  siteModules: "unloaded",
  fonts: "unloaded",
  issues: { checker: "unloaded", issues: "unloaded" },
  scripts: {},
};

const loadScript = (
  state: LoadStates,
  url: string,
  status: LoadStatus,
): LoadStates => {
  return { ...state, scripts: { ...state.scripts, [url]: status } };
};

const reducer = (state = initialState, action: AllActions): LoadStates => {
  switch (action.type) {
    case "LOAD_SCRIPT":
      return loadScript(state, action.url, action.status);

    case "GET_PARTNERS_START":
      return { ...state, partners: "loading" };

    case "GET_PARTNERS_SUCCESS":
      return { ...state, partners: "loaded" };

    case "GET_PAGES_START":
      return { ...state, pages: "loading" };

    case "GET_PAGES_SUCCESS":
      return { ...state, pages: "loaded" };

    case "GET_ALL_MEDIA_START":
      return {
        ...state,
        mediaLibrary: { ...state.mediaLibrary, pictures: "loading" },
      };

    case "GET_ALL_MEDIA_SUCCESS":
      return {
        ...state,
        mediaLibrary: { ...state.mediaLibrary, pictures: "loaded" },
      };

    case "GET_ALL_MEDIA_GROUPS_START":
      return {
        ...state,
        mediaLibrary: { ...state.mediaLibrary, groups: "loading" },
      };

    case "GET_ALL_MEDIA_GROUPS_SUCCESS":
      return {
        ...state,
        mediaLibrary: { ...state.mediaLibrary, groups: "loaded" },
      };

    case "GET_MEDIA_GROUP_START":
      return {
        ...state,
        mediaLibrary: {
          ...state.mediaLibrary,
          picturesByGroupId: {
            ...state.mediaLibrary.picturesByGroupId,
            [action.groupId]: "loading",
          },
        },
      };

    case "GET_MEDIA_GROUP_SUCCESS":
      return {
        ...state,
        mediaLibrary: {
          ...state.mediaLibrary,
          picturesByGroupId: {
            ...state.mediaLibrary.picturesByGroupId,
            [action.groupId]: "loaded",
          },
        },
      };

    case "GET_COLOR_SCHEMES_START":
      return { ...state, colorSchemes: "loading" };

    case "GET_COLOR_SCHEMES_SUCCESS":
      return { ...state, colorSchemes: "loaded" };

    case "GET_SITE_START":
      return { ...state, site: "loading" };

    case "GET_SITE_SUCCESS":
      return { ...state, site: "loaded" };

    case "GET_SPECIALS_START":
      return { ...state, specials: "loading" };

    case "GET_SPECIALS_SUCCESS":
      return { ...state, specials: "loaded" };

    case "GET_ROOMS_START":
      return { ...state, rooms: "loading" };

    case "GET_ROOMS_SUCCESS":
      return { ...state, rooms: "loaded" };

    case "GET_PRICES_START":
      return { ...state, prices: "loading" };

    case "GET_PRICES_SUCCESS":
      return { ...state, prices: "loaded" };

    case "GET_ACCOMMODATION_START":
      return {
        ...state,
        accommodation: {
          ...state.accommodation,
          [action.languageId]: "loading",
        },
      };

    case "GET_ACCOMMODATION_SUCCESS":
      return {
        ...state,
        accommodation: {
          ...state.accommodation,
          [action.languageId]: "loaded",
        },
      };

    case "GET_REVISIONS_START":
      return { ...state, revisions: "loading" };

    case "GET_REVISIONS_SUCCESS":
      return { ...state, revisions: "loaded" };

    case "GET_ACTION_MODULES_START":
      return { ...state, actionModules: "loading" };

    case "GET_ACTION_MODULES_SUCCESS":
      return { ...state, actionModules: "loaded" };

    case "GET_SITE_MODULES_START":
      return { ...state, siteModules: "loading" };

    case "GET_SITE_MODULES_SUCCESS":
      return { ...state, siteModules: "loaded" };

    case "GET_FONTS_START":
      return { ...state, fonts: "loading" };

    case "GET_FONTS_SUCCESS":
      return { ...state, fonts: "loaded" };

    case "GET_ISSUES_START":
      return { ...state, issues: { ...state.issues, issues: "loading" } };

    case "GET_ISSUES_SUCCESS":
      return { ...state, issues: { ...state.issues, issues: "loaded" } };

    case "GET_ISSUES_CHECK_START":
      return { ...state, issues: { ...state.issues, checker: "loading" } };

    case "GET_ISSUES_CHECK_SUCCESS":
      return { ...state, issues: { ...state.issues, checker: "loaded" } };

    default:
      return state;
  }
};

export default reducer;
