/**
 * In preview mode (`isPreview === true`) this component returns a
 * React Router link if it’s an internal link,
 * otherwise it returns a regular <a> tag.
 */

import { FunctionComponent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { AnchorAttrs } from "../types/index.js";
import { checkIsExternalLink } from "../utils/utils.js";

interface Props {
  isPreview: boolean;
  anchorAttrs: AnchorAttrs;
  children?: ReactNode;
}

const SmartLink: FunctionComponent<Props> = (props) => {
  const { isPreview, anchorAttrs, children: smartLinkChildren } = props;
  const {
    href,
    children: anchorChildren,
    target,
    ...partialAnchorAttrs
  } = anchorAttrs;
  const children = anchorChildren ?? smartLinkChildren;

  if (isPreview && target !== "_blank" && href && !checkIsExternalLink(href)) {
    return (
      <Link {...partialAnchorAttrs} target={target} to={href}>
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <a {...partialAnchorAttrs} target={target} href={href}>
        {children}
      </a>
    );
  }

  return <span {...partialAnchorAttrs}>{children}</span>;
};

export default SmartLink;
