import ClassNames from "classnames";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { throttle } from "throttle-debounce";
import { createTypeStyle } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";
import { insertScriptIntoHead } from "../utils/utils.js";
import Iframe from "./Iframe.js";

interface Props {
  scriptUrl: string;
  isActive: boolean;
  isPreview: boolean;
  style?: NestedCSSProperties;
  children: ReactNode;
  onLoad?: (iframe: HTMLIFrameElement) => void;
}

const IframeWidget: FunctionComponent<Props> = ({
  scriptUrl,
  children,
  isActive,
  isPreview,
  style,
  onLoad,
}) => {
  const [styleInstance, setStyleInstance] = useState(createTypeStyle());
  const [iframeHeight, setIframeHeight] = useState(0);
  const [isIframeShown, setIsIframeShown] = useState(true);

  const outerObserverRef = useRef<ResizeObserver>();
  const innerObserverRef = useRef<ResizeObserver>();
  const previousScriptUrlRef = useRef(scriptUrl);

  const onIframeLoaded = async (iframe: HTMLIFrameElement) => {
    const iframeDocument = iframe.contentDocument;
    if (!iframeDocument) return;
    const tag = document.createElement("style");
    iframeDocument.head.appendChild(tag);
    setStyleInstance(createTypeStyle(tag));

    const onResize = throttle(250, () => {
      setIframeHeight(iframeDocument.body.scrollHeight);
    });

    outerObserverRef.current = new ResizeObserver(onResize);
    outerObserverRef.current.observe(iframe);

    innerObserverRef.current = new ResizeObserver(onResize);
    innerObserverRef.current.observe(iframeDocument.body);

    await insertScriptIntoHead(scriptUrl, iframeDocument);
    onLoad?.(iframe);
  };

  useEffect(() => {
    return () => {
      outerObserverRef.current?.disconnect();
      innerObserverRef.current?.disconnect();
    };
  }, []);

  // Hide the iframe and load it again on provider setting change
  useEffect(() => {
    previousScriptUrlRef.current !== scriptUrl && setIsIframeShown(false);
    previousScriptUrlRef.current = scriptUrl;
  }, [scriptUrl]);

  // Show the iframe again as soon as it’s hidden
  useEffect(() => {
    !isIframeShown && setIsIframeShown(true);
  }, [isIframeShown]);

  return (
    isIframeShown && (
      <Iframe
        className={ClassNames("IframeWidget", {
          "IframeWidget--no-click": !isActive && isPreview,
        })}
        style={{ height: iframeHeight }}
        onIframeLoaded={onIframeLoaded}
        scrolling="no"
        loading="lazy"
      >
        <div className={styleInstance.style(style)}>{children}</div>
      </Iframe>
    )
  );
};

export default IframeWidget;
