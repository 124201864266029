import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { style } from "typestyle";
import { getActiveSite } from "../selectors/sites.js";
import {
  ColorScheme,
  Language,
  LanguageMenuItem,
  StoreState,
} from "../types/index.js";
import { getLanguageMenuItems } from "../utils/utils.js";
import LanguageMenuList from "./LanguageMenuList.js";
import LanguageMenuSelect from "./LanguageMenuSelect.js";

interface Props {
  isPreview: boolean;
  languageId: Language;
  scheme: ColorScheme | undefined;
  pageId: string;
  variant: "horizontal" | "vertical";
  className?: string;
  switchToSelectLimit?: number;
}

interface StateProps {
  items: LanguageMenuItem[];
}

type ReduxProps = ConnectedProps<typeof connector>;

const LanguageMenu: FunctionComponent<Props & ReduxProps> = ({
  isPreview,
  languageId,
  scheme,
  items,
  variant,
  className,
  switchToSelectLimit = 3,
}) => {
  const itemsLength = items.length;
  if (!itemsLength) return null;

  return (
    <div
      className={ClassNames(
        "LanguageMenu",
        `LanguageMenu--${variant}`,
        className,
        style({ background: scheme?.main.background }),
        {
          "LanguageMenu--show-list-on-wide": itemsLength <= switchToSelectLimit,
        },
      )}
    >
      <LanguageMenuSelect
        isPreview={isPreview}
        items={items}
        languageId={languageId}
      />

      {itemsLength <= switchToSelectLimit && (
        <LanguageMenuList
          isPreview={isPreview}
          items={items}
          languageId={languageId}
        />
      )}
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages, sites },
  { isPreview, pageId, variant },
): StateProps => ({
  items: getLanguageMenuItems({
    isPreview,
    pageId,
    pages,
    siteLanguages: getActiveSite(sites).languages,
    useFullLanguageNames: variant === "vertical",
  }),
});

const connector = connect(mapStateToProps);

export default connector(LanguageMenu);
