import { FunctionComponent, ReactNode } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteModuleTranslation,
  dragSubmodule,
  postModule,
  postMoveModule,
  translateModule,
} from "../actions/Modules.js";
import { isModuleWithImages } from "../selectors/modules.js";
import { getPictureById } from "../selectors/pictures.js";
import {
  ControlItem,
  Language,
  ModuleType,
  StoreState,
} from "../types/index.js";
import {
  getModulePreviewText,
  getModulesByParentId,
  getShortId,
  getTranslationLanguage,
  getURL,
} from "../utils/utils.js";
import ControlItemsList from "./ControlItemsList.js";
import Icon from "./Icon.js";
import MediaLibraryImage from "./MediaLibraryImage.js";

type ReduxProps = ConnectedProps<typeof connector>;

interface Previews {
  [subModuleId: string]: JSX.Element;
}

interface Props {
  siteId: string;
  pageId: string;
  createModulePageId?: string | null;
  languageId: Language;
  parentModuleId: string;
  moduleType: ModuleType;
  limit?: number;
  editPageId?: string;
  children?: ReactNode;
}

interface StateProps {
  sortableItems: ControlItem[];
}

const SubModulesList: FunctionComponent<Props & ReduxProps> = ({
  postModule,
  deleteModuleTranslation,
  translateModule,
  postMoveModule,
  dragSubmodule,
  siteId,
  pageId,
  createModulePageId = pageId,
  editPageId,
  languageId,
  parentModuleId,
  sortableItems,
  moduleType,
  limit,
  children,
}) => {
  const history = useHistory();
  const limitReached =
    limit !== undefined ? sortableItems.length >= limit : false;
  const editItemsBaseLink = getURL(
    siteId,
    "pages",
    editPageId || pageId,
    languageId,
    "modules",
  );
  const isImageList = moduleType === "ImageModule";
  const showAddRemove = editPageId === pageId || editPageId === undefined;

  const createSubmodule = () => {
    const subModuleId = getShortId();

    postModule({
      moduleId: subModuleId,
      siteId,
      pageId: createModulePageId,
      moduleType,
      parentId: parentModuleId,
      languageIds: [languageId],
      next: undefined,
    });

    history.push(getURL(editItemsBaseLink, subModuleId));
  };

  const createImage = () => {
    history.push(getURL(editItemsBaseLink, parentModuleId, "media"));
  };

  return (
    <div className="Form__Content Form__Content--compact Form__Content--column">
      {children}
      <ControlItemsList
        items={sortableItems}
        onChange={(subModuleId) => {
          history.push(getURL(editItemsBaseLink, String(subModuleId)));
        }}
        onTranslate={(subModuleId, sourceLanguageId) => {
          translateModule({
            siteId,
            moduleId: String(subModuleId),
            pageId,
            languageId,
            sourceLanguageId,
          });
        }}
        onDragEnd={({ oldIndex, newIndex, id: moduleId }) => {
          // If the drag index remains the same it is not necessary to push the
          // module to the server again
          if (oldIndex === newIndex) return;

          postMoveModule({
            moduleId: String(moduleId),
            pageId: createModulePageId,
            siteId,
            hasMovedBy: newIndex - oldIndex,
          });
        }}
        onDragOver={({ oldIndex, newIndex }) => {
          dragSubmodule({
            parentId: parentModuleId,
            moduleType,
            pageId: createModulePageId,
            dragIndex: oldIndex,
            hoverIndex: newIndex,
          });
        }}
        onRemove={
          // Only show the remove button if the submodule is on the same page as the
          // currently open page.
          showAddRemove
            ? (moduleId) => {
                deleteModuleTranslation({
                  siteId,
                  pageId,
                  languageId,
                  moduleId: String(moduleId),
                  deleteAllTranslations: !sharesTranslations(moduleType),
                });
              }
            : undefined
        }
      />

      {!limitReached && showAddRemove && (
        <button
          onClick={() => {
            isImageList ? createImage() : createSubmodule();
          }}
          className="Btn Form__Content__Button"
        >
          <Icon glyph="add" /> Hinzufügen
        </button>
      )}
    </div>
  );
};

const sharesTranslations = (moduleType: ModuleType): boolean => {
  switch (moduleType) {
    case "ImagesModule":
      return false;
    case "ImageModule":
      return false;
    default:
      return true;
  }
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { mediaLibrary: { pictures }, modules },
  {
    parentModuleId,
    languageId,
    moduleType,
    pageId,
    createModulePageId = pageId,
  },
): StateProps => {
  const subModules = getModulesByParentId(
    modules,
    parentModuleId,
    createModulePageId,
    moduleType,
  );

  const previews = subModules.reduce<Previews>((previews, module) => {
    if (!isModuleWithImages(module)) return previews;

    const picture = getPictureById(pictures, module.settings.pictureId, {
      width: 200,
      height: 80,
      mode: 1,
    });

    return {
      ...previews,
      [module.id]: (
        <MediaLibraryImage
          url={picture.url}
          background="dark"
          category={picture.category}
          width={200}
          height={80}
        />
      ),
    };
  }, {});

  const sortableItems = subModules.map<ControlItem>((subModule) => {
    const translationLanguageId = getTranslationLanguage(subModule, languageId);
    const previewText = getModulePreviewText(subModule, translationLanguageId);
    const isUntranslated =
      sharesTranslations(moduleType) && translationLanguageId !== languageId;

    const item: ControlItem = {
      id: subModule.id,
      title: isUntranslated
        ? `[${translationLanguageId.toUpperCase()}] ${previewText}`
        : previewText,
      translateFrom: isUntranslated ? translationLanguageId : undefined,
      preview: previews[subModule.id],
    };

    return item;
  });

  return { sortableItems };
};

const mapDispatchToProps = {
  postModule,
  translateModule,
  deleteModuleTranslation,
  postMoveModule,
  dragSubmodule,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SubModulesList);
