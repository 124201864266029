import { FunctionComponent } from "react";
import {
  AspectRatio,
  EmbeddedGoogleMapsModuleSettings,
  TranslatedModule,
} from "../../types/index.js";
import EmbeddedGoogleMaps from "../EmbeddedGoogleMaps.js";

interface Props {
  isActive: boolean;
  isPreview: boolean;
  aspectRatio: AspectRatio;
  translatedModule: TranslatedModule<EmbeddedGoogleMapsModuleSettings>;
}

const EmbeddedGoogleMapsModule: FunctionComponent<Props> = ({
  isActive,
  isPreview,
  aspectRatio,
  translatedModule: {
    translation: {
      settings: { url },
    },
  },
}) => (
  <EmbeddedGoogleMaps
    isActive={isActive}
    isPreview={isPreview}
    placeholder="Google Maps"
    url={url}
    aspectRatio={aspectRatio}
  />
);

export default EmbeddedGoogleMapsModule;
