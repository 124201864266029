import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getPageModules } from "../../selectors/modules.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  Language,
  Modules,
  QuestionAndAnswerModuleSettings,
  QuestionsAndAnswersModuleSettings,
  RichEditorState,
  StoreState,
  TranslatedModule,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getTranslatedModulesByParentId,
} from "../../utils/utils.js";
import JsonLd from "../JsonLd.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import QuestionAndAnswerModule from "./QuestionAndAnswerModule.js";

type Props = BaseModuleProps<QuestionsAndAnswersModuleSettings>;

interface StateProps {
  submodules: TranslatedModule<QuestionAndAnswerModuleSettings>[];
  scheme: ColorScheme;
  richEditor: RichEditorState;
  metadata: Metadata | undefined;
}

type ReduxProps = ConnectedProps<typeof connector>;

const QuestionsAndAnswersModule: FunctionComponent<Props & ReduxProps> = ({
  submodules,
  scheme,
  translatedModule: {
    id: moduleId,
    settings: { textAlign },
    translation: {
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  activeModuleId,
  isPreview,
  pageId,
  queries,
  richEditor,
  metadata,
}) => (
  <ModuleWithHeadings
    className="QuestionsAndAnswersModule"
    id={moduleId}
    colors={{ background: scheme.main.separator, color: scheme.main.text }}
    title={title}
    subtitle={subtitle}
  >
    <div className="Module__Wrapper Module__Wrapper--small-padding">
      <ModuleHeadings
        scheme={scheme}
        isFirstOnPage={isFirstOnPage}
        textAlign={textAlign}
        title={title}
        subtitle={subtitle}
      />

      <dl className="QuestionsAndAnswersModule__List">
        {submodules.map((submodule) => (
          <QuestionAndAnswerModule
            key={submodule.id}
            activeModuleId={activeModuleId}
            isActive={activeModuleId === submodule.id}
            isFirstOnPage={isFirstOnPage}
            isPreview={isPreview}
            pageId={pageId}
            queries={queries}
            translatedModule={submodule}
            scheme={scheme}
            richEditor={richEditor}
          />
        ))}
      </dl>
      {metadata && <JsonLd data={metadata} />}
    </div>
  </ModuleWithHeadings>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules, colorSchemes, sites, richEditor },
  {
    translatedModule,
    translatedModule: {
      id: moduleId,
      translation: { languageId },
    },
    pageId,
  },
): StateProps => {
  const moduleIds = getPageModules(modules, pageId)
    .filter(({ type }) => type === "QuestionsAndAnswersModule")
    .map(({ id }) => id);
  const isLastQAModule = moduleId === moduleIds.slice(-1)[0];

  return {
    submodules: getQASubmodules({ modules, languageId, pageId, moduleId }),
    scheme: getActiveColorScheme(
      colorSchemes,
      getActiveSite(sites),
      translatedModule,
    ),
    richEditor,
    // Only include the metadata after the last QuestionsAndAnswersModule
    metadata: isLastQAModule
      ? getMetadata(
          getAllPageQASubmodules({ modules, languageId, moduleIds, pageId }),
        )
      : undefined,
  };
};

interface MetaQuestion {
  "@type": "Question";
  name: string;
  acceptedAnswer: { "@type": "Answer"; text: string };
}

interface Metadata {
  "@context": "https://schema.org";
  "@type": "FAQPage";
  mainEntity: MetaQuestion[];
}

/**
 * Get the JSON-LD metadata for all questions and answers on the current page.
 */
const getMetadata = (
  submodules: TranslatedModule<QuestionAndAnswerModuleSettings>[],
): Metadata => ({
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: submodules.map(
    ({
      translation: {
        settings: { title, plainDescription },
      },
    }) => ({
      "@type": "Question",
      name: title,
      acceptedAnswer: { "@type": "Answer", text: plainDescription ?? "" },
    }),
  ),
});

const getQASubmodules = ({
  modules,
  languageId,
  pageId,
  moduleId,
}: {
  modules: Modules;
  languageId: Language;
  pageId: string;
  moduleId: string;
}) =>
  getTranslatedModulesByParentId<QuestionAndAnswerModuleSettings>({
    modules,
    languageId,
    pageId,
    parentId: moduleId,
    moduleType: "QuestionAndAnswerModule",
  });

/**
 * Get all QuestionAndAnswerModules of the current page
 * (children of all QuestionsAndAnswersModules)
 */
const getAllPageQASubmodules = ({
  modules,
  languageId,
  pageId,
  moduleIds,
}: {
  modules: Modules;
  languageId: Language;
  pageId: string;
  moduleIds: string[];
}) =>
  moduleIds.reduce<TranslatedModule<QuestionAndAnswerModuleSettings>[]>(
    (carry, moduleId) => [
      ...carry,
      ...getQASubmodules({ modules, languageId, pageId, moduleId }),
    ],
    [],
  );

const connector = connect(mapStateToProps);

export default connector(QuestionsAndAnswersModule);
