import { FunctionComponent } from "react";
import { spriteData } from "../../dist/svg-sprite/feature-icons-data.js";
import SvgUseIcon from "./SvgUseIcon.js";

const { spriteFileName, viewBoxes, symbolIdPrefix } = spriteData;

interface Props {
  className?: string;
  featureId: number;
}

const AccommodationFeatureIcon: FunctionComponent<Props> = ({
  featureId,
  className,
}) => {
  const glyph = ("feature-" + featureId) as keyof typeof viewBoxes;
  const viewBox = viewBoxes[glyph];

  if (!viewBox) return null;

  return (
    <SvgUseIcon
      className={className}
      viewBox={viewBox}
      glyph={glyph}
      symbolIdPrefix={symbolIdPrefix}
      spriteFileName={spriteFileName}
      svgClassName="AccommodationFeaturesModule__IconInner"
    />
  );
};

export default AccommodationFeatureIcon;
