import ClassNames from "classnames";
import { FunctionComponent, MouseEvent } from "react";
import {
  ColorScheme,
  Language,
  MainMenuItem,
  PageState,
  TopHeaderVariant,
} from "../types/index.js";
import { getMenuItems, isTouchDevice } from "../utils/utils.js";
import Icon from "./Icon.js";
import MainMenuList from "./MainMenuList.js";
import SmartLink from "./SmartLink.js";

interface Props {
  isPreview: boolean;
  languageId: Language;
  parentId: string;
  pages: PageState;
  currentMenuItem: MainMenuItem;
  scheme: ColorScheme | undefined;
  onMenuItemClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  menuLevel: number;
  topHeaderVariant: TopHeaderVariant | undefined;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  liRef?: (instance: HTMLLIElement | null) => void;
  isExpanded?: boolean;
  onToggleExpanded?: (index: number | undefined) => void;
  index: number;
  repeatFirstLevelItem: boolean;
}

const MainMenuLevel: FunctionComponent<Props> = ({
  isPreview,
  languageId,
  parentId,
  pages,
  currentMenuItem,
  scheme,
  onMenuItemClick,
  menuLevel,
  topHeaderVariant,
  activePagePath,
  fallbackLanguageId,
  liRef,
  isExpanded = true,
  index,
  onToggleExpanded,
  repeatFirstLevelItem,
}) => {
  const menuItems = getMenuItems({
    pages,
    pageId: parentId,
    languageId,
    isPreview,
    fallbackLanguageId,
  });
  const hasSubpages = !!menuItems.length;
  const { title, href, id, isFolder, target } = currentMenuItem;

  const activePageIndex = activePagePath.indexOf(id);

  const listItemClassName = ClassNames("MainMenu__ListItem", {
    MainMenu__ListItemLevel0: menuLevel === 0,
    MainMenu__ListItemLevel1: menuLevel === 1,
    MainMenu__ListItemLevel2up: menuLevel >= 2,
  });

  const linkItemClassName = ClassNames("MainMenu__Item", {
    MainMenu__ItemLevel0: menuLevel === 0,
    MainMenu__ItemLevel1: menuLevel === 1,
    MainMenu__ItemLevel2up: menuLevel >= 2,
    "MainMenu__Item--active": activePageIndex > -1,
  });

  const nextMenuLevel = menuLevel + 1;

  const linkContent = <span className="MainMenu__Item__Text">{title}</span>;

  return (
    <li ref={liRef} className={ClassNames(listItemClassName)}>
      <div className="MainMenu__ItemWrap">
        {!isPreview && isFolder ? (
          <span className={linkItemClassName} onClick={onMenuItemClick}>
            {linkContent}
          </span>
        ) : (
          <SmartLink
            isPreview={isPreview}
            anchorAttrs={{
              href,
              target,
              className: linkItemClassName,
              onClick: (event) => {
                menuLevel === 0 &&
                  isTouchDevice &&
                  hasSubpages &&
                  event.preventDefault();

                onMenuItemClick && onMenuItemClick(event);
              },
            }}
          >
            {linkContent}
          </SmartLink>
        )}

        {onToggleExpanded && hasSubpages && (
          <button
            type="button"
            className="MainMenu__ExpandButton"
            onClick={() => onToggleExpanded(isExpanded ? undefined : index)}
          >
            <Icon
              className="MainMenu__ExpandIcon"
              glyph={isExpanded ? "arrow-up" : "arrow-down"}
            />
          </button>
        )}
      </div>

      {isExpanded && (
        <MainMenuList
          isPreview={isPreview}
          languageId={languageId}
          parentMenuItem={currentMenuItem}
          menuItems={menuItems}
          pages={pages}
          scheme={scheme}
          onMenuItemClick={onMenuItemClick}
          menuLevel={nextMenuLevel}
          topHeaderVariant={topHeaderVariant}
          activePagePath={activePagePath}
          fallbackLanguageId={fallbackLanguageId}
          repeatFirstLevelItem={repeatFirstLevelItem}
        />
      )}
    </li>
  );
};

export default MainMenuLevel;
