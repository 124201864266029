import { FunctionComponent } from "react";
import { CommonHeaderLayoutProps } from "../types/index.js";
import FullHeaderWithLogo from "./FullHeaderWithLogo.js";
import Header4And5Mobile from "./HeaderMobile/Header4And5Mobile.js";
import HeaderSidebar from "./HeaderSidebar.js";

const HeaderLayout5: FunctionComponent<CommonHeaderLayoutProps> = (props) => {
  const {
    actionLinks,
    imageOverlayGradient,
    imagesModule,
    isPreview,
    languageId,
    logo,
    logoSize,
    logoBackground,
    mainPageURL,
    pageId,
    queries,
    scheme,
    showSouthTyrolLogo,
    whatsAppNumber,
  } = props;

  return (
    <>
      {queries["Query--large"] && (
        <HeaderSidebar
          scheme={scheme}
          languageId={languageId}
          isPreview={isPreview}
          pageId={pageId}
          actionLinks={actionLinks}
          whatsAppNumber={whatsAppNumber}
        />
      )}

      {imagesModule?.pageId && (
        <FullHeaderWithLogo
          imagesModule={imagesModule}
          isPreview={isPreview}
          languageId={languageId}
          pageId={pageId}
          queries={queries}
          scheme={scheme}
          showSouthTyrolLogo={showSouthTyrolLogo}
          logoBackground={logoBackground}
          imageOverlayGradient={imageOverlayGradient}
          logo={logo}
          logoSize={logoSize}
          mainPageURL={mainPageURL}
        />
      )}

      <Header4And5Mobile {...props} />
    </>
  );
};

export default HeaderLayout5;
