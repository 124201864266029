import ClassNames from "classnames";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  ColorScheme,
  Language,
  ModuleWithRichTextSettingsCombined,
  RichEditorProps,
  TranslatedModule,
} from "../types/index.js";
import {
  checkIsModuleWithDraftState,
  getLinkStyleClass,
} from "../utils/utils.js";

interface Props {
  module: TranslatedModule<ModuleWithRichTextSettingsCombined>;
  pageId: string;
  languageId: Language;
  isPreview: boolean;
  // Link styling is not applied if scheme === undefined
  scheme: ColorScheme | undefined;
  maxCharacters?: number;
  className: string;
}

const RichEditorWrapper: FunctionComponent<Props> = ({
  module,
  pageId,
  isPreview,
  languageId,
  scheme,
  maxCharacters,
  className,
}) => {
  const RichEditor = useLoadRichEditor(isPreview);
  const { description } = module.translation.settings;
  const linkStyleClass = scheme && getLinkStyleClass(scheme);
  const classNames = ClassNames(className, "Module__BodyText", linkStyleClass);

  if (typeof description === "string") {
    return (
      <div
        className={classNames}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    );
  }

  if (isPreview && checkIsModuleWithDraftState(module) && RichEditor) {
    return (
      <div className={classNames}>
        <RichEditor
          module={module}
          pageId={pageId}
          languageId={languageId}
          isPreview={isPreview}
          maxCharacters={maxCharacters}
        />
      </div>
    );
  }

  return null;
};

const useLoadRichEditor = (
  isPreview: boolean,
): FunctionComponent<RichEditorProps> | undefined => {
  const [component, setComponent] =
    useState<FunctionComponent<RichEditorProps>>();
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;

    isPreview &&
      import("./RichEditor.js").then(
        // Load the rich editor in preview (admin panel)
        // but not on the published websites.
        (m) => isMountedRef.current && setComponent(m.default),
      );

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return component;
};

export default RichEditorWrapper;
