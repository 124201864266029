import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { style } from "typestyle";
import {
  ColorScheme,
  ContainerQueries,
  HighlightsItem,
  HighlightsLayout5AspectRatio,
  TitleAndSubtitleTextAlign,
} from "../types/index.js";
import HighlightsLayout5Item from "./HighlightsLayout5Item.js";
import ModuleHeadings from "./ModuleHeadings.js";

interface Props {
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  isFirstOnPage: boolean;
  scheme: ColorScheme;
  items: HighlightsItem[];
  queries: ContainerQueries;
  isPreview: boolean;
}

const HighlightsLayout5: FunctionComponent<Props> = ({
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  scheme,
  items: itemsFromProps,
  isPreview,
  queries,
}) => {
  const items = itemsFromProps.slice(0, 4);
  const itemsCount = items.length;
  const hasHighlightsTitle = !!title;

  return (
    <div
      className={ClassNames(
        "HighlightsLayout5",
        style({ background: scheme.main.separator }),
      )}
    >
      <div className="Module__Wrapper Module__Wrapper--default-padding">
        <div
          className={ClassNames(
            "HighlightsLayout5__Items",
            `HighlightsLayout5__Items--count-${itemsCount}`,
          )}
        >
          <div className="HighlightsLayout5__ItemWrap">
            <ModuleHeadings
              className="HighlightsLayout5__Headings"
              scheme={scheme}
              isFirstOnPage={isFirstOnPage}
              textAlign={textAlign}
              title={title}
              subtitle={subtitle}
            />
            {items[0] && (
              <HighlightsLayout5Item
                isPreview={isPreview}
                item={items[0]}
                aspectRatio={getAspectRatio({ queries, itemsCount, index: 0 })}
                hasHighlightsTitle={hasHighlightsTitle}
                index={0}
                isFirstOnPage={isFirstOnPage}
              />
            )}
          </div>

          {items.slice(1, 4).map((item, index) => (
            <div className="HighlightsLayout5__ItemWrap" key={item.id}>
              <HighlightsLayout5Item
                isPreview={isPreview}
                item={item}
                aspectRatio={getAspectRatio({
                  queries,
                  itemsCount,
                  index: index + 1,
                })}
                hasHighlightsTitle={hasHighlightsTitle}
                index={index + 1}
                isFirstOnPage={isFirstOnPage}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const getAspectRatio = ({
  itemsCount,
  queries,
  index,
}: {
  itemsCount: number;
  queries: ContainerQueries;
  index: number;
}): HighlightsLayout5AspectRatio => {
  if (itemsCount === 3 && queries["Query--xlarge"]) {
    switch (index) {
      case 0:
        return 0.8696;
      case 1:
        return 1.4286;
      case 2:
        return 1.1364;
    }
  }

  const use4PicturesAspectRatios =
    itemsCount === 4 && (queries["Query--xlarge"] || !queries["Query--medium"]);

  if (use4PicturesAspectRatios) {
    switch (index) {
      case 0:
        return 0.8696;
      case 1:
        return 1.8182;
      case 2:
        return 2.2222;
      case 3:
        return 0.8696;
    }
  }

  return 1.5;
};

export default HighlightsLayout5;
