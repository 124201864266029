import { FunctionComponent, useEffect, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import tinycolors from "tinycolor2";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  QuickEnquiryModuleSettings,
  QuickEnquiryWidgetSettings,
  StoreState,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getSupportedLanguage,
} from "../../utils/utils.js";
import QuickEnquiryWidget from "../QuickEnquiryWidget.js";

interface Props extends BaseModuleProps<QuickEnquiryModuleSettings> {}

interface StateProps {
  settings: QuickEnquiryWidgetSettings;
}

type ReduxProps = ConnectedProps<typeof connector>;

const QuickEnquiryModule: FunctionComponent<Props & ReduxProps> = ({
  settings,
  isActive,
  isPreview,
}) => {
  const [isShown, setIsShown] = useState(isPreview);

  useEffect(() => {
    const showWidget = () => {
      setIsShown(true);
      document.removeEventListener("scroll", showWidget);
    };

    // If the document is already scrolled vertically,
    // load the widget immediately, otherwise
    // wait until the user scrolls.
    window.scrollY
      ? showWidget()
      : document.addEventListener("scroll", showWidget);

    return () => {
      document.removeEventListener("scroll", showWidget);
    };
  }, []);

  // Don’t render anything before accommodation is loaded
  // Hide the module if it’s in preview and inactive
  return !settings.propertyId || (isPreview && !isActive) || !isShown ? null : (
    <div className="QuickEnquiryModule">
      <QuickEnquiryWidget settings={settings} />
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule, isPreview },
): StateProps => {
  const site = getActiveSite(sites);

  const {
    translation: { languageId },
    settings: { layout, fieldSettings, badgeStyle },
  } = translatedModule;

  const scheme = getActiveColorScheme(colorSchemes, site, translatedModule);

  const settings: QuickEnquiryWidgetSettings = {
    fieldSettings,
    propertyId: site.accommodation.id,
    lang: getSupportedLanguage(languageId, ["de", "it", "en", "fr"]),
    layout,
    badgeStyle,
    // Show form by default in preview (CMS)
    onLoad: ({ showForm }) => showForm(isPreview),
    colorScheme: {
      ...scheme,
      main: {
        ...scheme.main,
        separator: tinycolors(scheme.main.separator).lighten(2).toRgbString(),
      },
    },
  };

  return { settings };
};

const connector = connect(mapStateToProps);

export default connector(QuickEnquiryModule);
