import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { RemoveScroll } from "react-remove-scroll";
import {
  ActionLinks,
  Language,
  MainMenuItem,
  PageState,
} from "../types/index.js";
import ActionModuleNavigation from "./ActionModuleNavigation.js";
import FullMenuButton from "./FullMenuButton.js";
import MainMenuListCollapsible, {
  defaultClassNames,
} from "./MainMenuListCollapsible.js";

interface Props {
  languageId: Language;
  onClose: () => void;
  isPreview: boolean;
  pages: PageState;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  menuItems: MainMenuItem[];
  isMultiLevel: boolean;
  actionLinks: ActionLinks;
}

const FullHeaderMenu: FunctionComponent<Props> = ({
  languageId,
  onClose,
  isPreview,
  activePagePath,
  fallbackLanguageId,
  menuItems,
  pages,
  isMultiLevel,
  actionLinks,
}) => {
  const count = menuItems.length;
  return (
    <RemoveScroll>
      <div className="FullHeaderMenu">
        <div className="FullHeaderMenu__Inner">
          <div className="FullHeaderMenu__Container">
            <FullMenuButton
              isOpen={true}
              languageId={languageId}
              onClick={onClose}
            />
            <div
              className={ClassNames("FullHeaderMenu__MenuWrapper", {
                "FullHeaderMenu__MenuWrapper--multi-level": isMultiLevel,
                "FullHeaderMenu__MenuWrapper--max-3-columns":
                  count % 3 === 0 && count % 4 !== 0,
                "FullHeaderMenu__MenuWrapper--max-4-columns": count % 4 === 0,
              })}
            >
              <MainMenuListCollapsible
                isPreview={isPreview}
                languageId={languageId}
                onMenuItemClick={onClose}
                menuItems={menuItems}
                pages={pages}
                activePagePath={activePagePath}
                fallbackLanguageId={fallbackLanguageId}
                menuLevel={0}
                collapseLevel={1}
                classNames={defaultClassNames}
              />
            </div>
          </div>
          <ActionModuleNavigation
            actionLinks={actionLinks}
            isPreview={isPreview}
            scheme={undefined}
            onItemClick={onClose}
            showQuickLinks={false}
          />
        </div>
      </div>
    </RemoveScroll>
  );
};

export default FullHeaderMenu;
