import { AllActions } from "../actions/index.js";
import { Language } from "../types/index.js";

const initialState: Language[] = [];

const getLanguagesSuccess = (languages: Language[]) => {
  return languages;
};

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "GET_LANGUAGES_SUCCESS":
      return getLanguagesSuccess(action.languages);

    default:
      return state;
  }
};

export default reducer;
