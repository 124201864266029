import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  StoreState,
  TranslatedModule,
  WeatherModuleSettings,
  WeatherWebcamModuleSettings,
  WebcamModuleSettings,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getTranslatedModulesByParentId,
} from "../../utils/utils.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import WeatherModule from "./WeatherModule.js";
import WebcamModule from "./WebcamModule.js";

type Props = BaseModuleProps<WeatherWebcamModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
  weatherModule: TranslatedModule<WeatherModuleSettings> | undefined;
  webcamModules: TranslatedModule<WebcamModuleSettings>[];
}

type ReduxProps = ConnectedProps<typeof connector>;

const WeatherWebcamModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  weatherModule,
  webcamModules,
  queries,
  pageId,
  isPreview,
  activeModuleId,
  isFirstOnPage,
  translatedModule,
  translatedModule: {
    settings: { layoutVariant },
  },
}) => (
  <ModuleWithHeadings
    title={undefined}
    subtitle={undefined}
    id={translatedModule.id}
    className="WeatherWebcamModule"
    colors={{ background: scheme.main.background, color: scheme.main.text }}
  >
    {isPreview && !weatherModule && !webcamModules.length && (
      <div className="Module__Wrapper WeatherWebcamModule__Placeholder">
        <p>Fügen Sie ein Wetter- oder Webcam-Modul hinzu</p>
      </div>
    )}

    <div
      className={ClassNames("WeatherWebcamModule__Inner", "Module__Wrapper", {
        "WeatherWebcamModule__Inner--horizontal":
          layoutVariant === "horizontal" && queries["Query--large"],
        "WeatherWebcamModule__Inner--vertical":
          layoutVariant === "vertical" || !queries["Query--large"],
        Module__Wrapper: layoutVariant === "vertical",
      })}
    >
      {weatherModule && (
        <div className="WeatherWebcamModule__Weather">
          <WeatherModule
            isActive={weatherModule.id === activeModuleId}
            translatedModule={weatherModule}
            queries={queries}
            pageId={pageId}
            isPreview={isPreview}
            activeModuleId={activeModuleId}
            isFirstOnPage={isFirstOnPage}
            scheme={scheme}
          />
        </div>
      )}

      {!!webcamModules.length && (
        <div className="WeatherWebcamModule__Webcams">
          {webcamModules.map((webcamModule) => (
            <WebcamModule
              key={webcamModule.id}
              isActive={webcamModule.id === activeModuleId}
              translatedModule={webcamModule}
              queries={queries}
              pageId={pageId}
              isPreview={isPreview}
              activeModuleId={activeModuleId}
              isFirstOnPage={weatherModule ? false : isFirstOnPage}
              scheme={scheme}
            />
          ))}
        </div>
      )}
    </div>
  </ModuleWithHeadings>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { sites, colorSchemes, modules },
  {
    translatedModule,
    translatedModule: {
      translation: { languageId },
    },
    pageId,
  },
): StateProps => {
  const site = getActiveSite(sites);
  const webcamModules = getTranslatedModulesByParentId<WebcamModuleSettings>({
    modules,
    parentId: translatedModule.id,
    pageId,
    moduleType: "WebcamModule",
    languageId,
  });

  const weatherModules = getTranslatedModulesByParentId<WeatherModuleSettings>({
    modules,
    parentId: translatedModule.id,
    pageId,
    moduleType: "WeatherModule",
    languageId,
  });

  const weatherModule = weatherModules.length ? weatherModules[0] : undefined;

  return {
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
    webcamModules,
    weatherModule,
  };
};

const connector = connect(mapStateToProps);

export default connector(WeatherWebcamModule);
