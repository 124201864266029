import { FunctionComponent, ReactNode, useEffect } from "react";
import { Language, UsercentricsServiceName } from "../types/index.js";
import {
  getUsercentricsConsent as getUsercentricsConsentStatus,
  onUsercentricsConsent,
} from "../utils/usercentrics.js";
import ModuleConsentBox from "./ModuleConsentBox.js";

interface Props {
  onConsentGiven: () => void;
  consentIsGiven: boolean;
  serviceName: UsercentricsServiceName;
  languageId: Language;
  children: ReactNode;
}

const UsercentricsCMPWrapper: FunctionComponent<Props> = ({
  onConsentGiven,
  consentIsGiven,
  children,
  serviceName,
  languageId,
}) => {
  useEffect(() => {
    const onLoad = async () => {
      if (consentIsGiven) return;

      const isGiven = await getUsercentricsConsentStatus(serviceName);
      isGiven
        ? onConsentGiven()
        : onUsercentricsConsent(serviceName).then(onConsentGiven);
    };

    onLoad();
  }, []);

  return (
    <>
      {consentIsGiven ? (
        children
      ) : (
        <ModuleConsentBox
          serviceName={serviceName}
          languageId={languageId}
          onConsentGiven={onConsentGiven}
        />
      )}
    </>
  );
};

export default UsercentricsCMPWrapper;
