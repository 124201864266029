import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getSouthTyrolLogo } from "../selectors/partnerLogos.js";
import {
  ColorScheme,
  ContainerQueries,
  HeaderImageOverlayGradient,
  HeaderLogoBackground,
  ImagesModuleSettings,
  Language,
  Picture,
  StoreState,
  ThreeSizes,
  TranslatedModule,
  TranslatedPartnerLogo,
} from "../types/index.js";
import { getImageModuleWithFixedAspectRatio } from "../utils/utils.js";
import HeaderLogo from "./HeaderLogo.js";
import ImagesModule from "./Modules/ImagesModule.js";
import PartnerLogo from "./PartnerLogo.js";

interface Props {
  imagesModule: TranslatedModule<ImagesModuleSettings>;
  pageId: string;
  queries: ContainerQueries;
  scheme: ColorScheme;
  isPreview: boolean;
  languageId: Language;
  showSouthTyrolLogo: boolean;
  logoBackground: HeaderLogoBackground;
  imageOverlayGradient: HeaderImageOverlayGradient;
  logo: Picture;
  logoSize: ThreeSizes;
  mainPageURL: string | undefined;
}

interface StateProps {
  southTyrolLogo: TranslatedPartnerLogo | undefined;
}

type ReduxProps = ConnectedProps<typeof connector>;

const FullHeaderWithLogo: FunctionComponent<Props & ReduxProps> = ({
  imagesModule,
  isPreview,
  pageId,
  queries,
  scheme,
  logo,
  logoSize,
  mainPageURL,
  southTyrolLogo,
  logoBackground,
  imageOverlayGradient,
}) => (
  <div
    className={ClassNames(
      "HeaderModule__Inner",
      "HeaderModule__Inner--wide",
      "HeaderModule__Inner--fixed-sidebar",
      `HeaderModule__Inner--image-overlay-gradient-${imageOverlayGradient}`,
      { "HeaderModule__Inner--full-height": !isPreview },
    )}
  >
    <div
      className={ClassNames("HeaderModule__LogosWrapper", {
        "HeaderModule__LogosWrapper--with-partner-logo": !!southTyrolLogo,
      })}
    >
      <HeaderLogo
        logo={logo}
        mainPageURL={mainPageURL}
        isPreview={isPreview}
        scheme={scheme}
        logoBackground={logoBackground}
        maxSize={{ width: 300, height: 100 }}
        size={logoSize}
      />

      {southTyrolLogo && (
        <div className="HeaderModule__LogosWrapperPartner">
          <PartnerLogo partnerLogo={southTyrolLogo} size="big" />
        </div>
      )}
    </div>

    <ImagesModule
      className="FullScreenHeaderImages"
      translatedModule={getImageModuleWithFixedAspectRatio(imagesModule)}
      isPreview={isPreview}
      pageId={pageId}
      queries={queries}
      isActive={false}
      isInsideHeader={true}
      isFirstOnPage={false}
      parentScheme={scheme}
      activeModuleId={undefined}
      fullHeight={!isPreview}
    />
  </div>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  state,
  { languageId, showSouthTyrolLogo },
): StateProps => ({
  southTyrolLogo: showSouthTyrolLogo
    ? getSouthTyrolLogo(state, languageId)
    : undefined,
});

const connector = connect(mapStateToProps);

export default connector(FullHeaderWithLogo);
