import ClassNames from "classnames";
import { FunctionComponent, useState } from "react";
import { style } from "typestyle";
import {
  Accommodation,
  ActionLinks,
  ColorScheme,
  Language,
  MainMenuItem,
  PageState,
  Picture,
  ThreeSizes,
} from "../types/index.js";
import {
  BarInner,
  Header1LogoContainer,
  Menu,
} from "./HeaderMobile/Header1Mobile.js";
import { Header3LogoContainer } from "./HeaderMobile/Header3Mobile.js";

interface Props {
  actionLinks: ActionLinks;
  activePagePath: string[];
  hasHeaderImage: boolean;
  isPreview: boolean;
  languageId: Language;
  pages: PageState;
  scheme: ColorScheme;
  topLevelMainMenuItems: MainMenuItem[];
  fallbackLanguageId: Language | undefined;
  pageId: string;
  logo: Picture;
  logoSize: ThreeSizes;
  mainPageURL: string | undefined;
  accommodation: Accommodation;
  isCircleHamburger: boolean;
}

const CompactHeaderBar: FunctionComponent<Props> = ({
  actionLinks,
  logo,
  logoSize,
  activePagePath,
  fallbackLanguageId,
  pages,
  topLevelMainMenuItems,
  isPreview,
  languageId,
  mainPageURL,
  pageId,
  scheme,
  accommodation,
  isCircleHamburger = false,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <div className={`CompactHeaderBar--height-${logoSize}`}>
      <div
        className={ClassNames(
          "CompactHeaderBar",
          style({
            backgroundColor: scheme.main.background,
            color: scheme.main.text,
          }),
        )}
      >
        <header>
          <BarInner
            accommodation={accommodation}
            isPreview={isPreview}
            logo={logo}
            mainPageURL={mainPageURL}
            logoSize={logoSize}
            actionLinks={actionLinks}
            languageId={languageId}
            closeMenu={closeMenu}
          >
            {(isCircleHamburger ? Header3LogoContainer : Header1LogoContainer)({
              isMenuOpen,
              toggleMenuOpen: () => setIsMenuOpen((v) => !v),
            })}
          </BarInner>
        </header>
      </div>
      <Menu
        activePagePath={activePagePath}
        fallbackLanguageId={fallbackLanguageId}
        menuItems={topLevelMainMenuItems}
        pages={pages}
        scheme={scheme}
        accommodation={accommodation}
        languageId={languageId}
        whatsAppNumber={undefined}
        actionLinks={actionLinks}
        isPreview={isPreview}
        pageId={pageId}
        isMenuOpen={isMenuOpen}
        closeMenu={closeMenu}
      />
    </div>
  );
};

export default CompactHeaderBar;
