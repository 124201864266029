import { minify } from "csso";
import { Font, FontsByFamily, FontSubset } from "../types/index.js";
import { isDefined } from "./utils.js";

// Font variants returned in the format of the
// Google Fonts API (https://developers.google.com/fonts/docs/developer_api)
export const fontVariants = <const>[
  "300",
  "300italic",
  "regular",
  "italic",
  "600",
  "600italic",
  "700",
  "700italic",
];

export const fontWeights = <const>["300", "400", "600", "700"];
export const fontStyles = <const>["normal", "italic"];
export const fontSubsets = <const>["latin", "latin-ext", "cyrillic"];

export const getFontFaces = (font: Font): FontFace[] =>
  font.variants.map<FontFace>(
    ({ url, style, subset, weight }) =>
      new FontFace(font.family, `url("${url}")`, {
        display: "swap",
        style,
        weight,
        unicodeRange: subsetMap[subset],
      }),
  );

const subsetMap: { [subset in FontSubset]: string } = {
  latin: [
    "U+0000-00FF",
    "U+0131",
    "U+0152-0153",
    "U+02BB-02BC",
    "U+02C6",
    "U+02DA",
    "U+02DC",
    "U+2000-206F",
    "U+2074",
    "U+20AC",
    "U+2122",
    "U+2191",
    "U+2193",
    "U+2212",
    "U+2215",
    "U+FEFF",
    "U+FFFD",
  ].join(),
  "latin-ext": [
    "U+0100-024F",
    "U+0259",
    "U+1E00-1EFF",
    "U+2020",
    "U+20A0-20AB",
    "U+20AD-20CF",
    "U+2113",
    "U+2C60-2C7F",
    "U+A720-A7FF",
  ].join(),
  cyrillic: [
    "U+0301",
    "U+0400-045F",
    "U+0490-0491",
    "U+04B0-04B1",
    "U+2116",
  ].join(),
};

/**
 * Get the CSS with the @font-face rules of the given fonts.
 * Inlining them into a <style> tag is better than serving
 * a separate CSS file, see
 * https://web.dev/font-best-practices/#inline-font-declarations.
 */
export const getFontFaceCSS = (fonts: Font[]): string => {
  const css = fonts
    .map(({ family, variants }) =>
      variants
        .map(
          ({ weight, style, subset, url }) => `
            @font-face {
              font-family: "${family}";
              font-style: ${style};
              font-display: swap;
              font-weight: ${weight};
              unicode-range: ${subsetMap[subset]};
              src: url("${url}") format("woff2");
            }
          `,
        )
        .join("\n"),
    )
    .join("\n");

  return minify(css).css;
};

export const getFontsByFamilyNames = (
  byFamily: FontsByFamily,
  familyNames: string[],
): Font[] => familyNames.map((family) => byFamily[family]).filter(isDefined);

export const addAndLoadFonts = (fontFaces: FontFace[]) => {
  fontFaces.forEach((font) => {
    document.fonts.add(font);
    font.load();
  });
};

export const deleteFonts = (fontFaces: FontFace[]) => {
  fontFaces.forEach((font) => document.fonts.delete(font));
};

export const checkFontExists = (family: string) => {
  const loadStatuses: FontFaceLoadStatus[] = ["loaded", "loading"];
  return Array.from(document.fonts).some(
    (f) => f.family === family && loadStatuses.includes(f.status),
  );
};

export const mergeMainAndTitleFamilies = (
  mainFamily: string,
  titleFamily: string | undefined,
): string[] => [mainFamily, titleFamily].filter(isDefined);
