import ClassNames from "classnames";
import { CSSProperties, FunctionComponent } from "react";
import { checkIsPlaceholder } from "../../selectors/pictures.js";
import { Picture, ThreeSizes } from "../../types/index.js";
import { fitRectWidth, logoSizeMultipliers } from "../../utils/utils.js";
import SmartLink from "../SmartLink.js";

interface Props {
  accommodationName: string | undefined;
  isPreview: boolean;
  isSmall: boolean;
  logo: Picture;
  mainPageURL: string | undefined;
  size: ThreeSizes;
}

const MobileMainLogo: FunctionComponent<Props> = ({
  accommodationName,
  isPreview,
  logo,
  mainPageURL,
  isSmall,
  size,
}) =>
  checkIsPlaceholder(logo) ? null : (
    <div className="MobileMainLogo">
      <SmartLink anchorAttrs={{ href: mainPageURL }} isPreview={isPreview}>
        <img
          className={ClassNames("MobileMainLogo__Image", {
            "MobileMainLogo__Image--small": isSmall,
          })}
          src={logo.url}
          title={logo.title ?? undefined}
          alt={logo.title ?? accommodationName ?? ""}
          width={logo.width}
          height={logo.height}
          style={
            {
              "--small-width":
                getWidth(logo, { w: 160, h: 45 }, "medium") + "px",
              "--narrow-width":
                getWidth(logo, { w: 160, h: 73 }, "medium") + "px",
              "--wide-width": getWidth(logo, { w: 220, h: 73 }, size) + "px",
            } as CSSProperties
          }
        />
      </SmartLink>
    </div>
  );

const getWidth = (
  logo: Picture,
  { w, h }: { w: number; h: number },
  size: ThreeSizes,
) =>
  Math.round(
    logoSizeMultipliers[size] *
      fitRectWidth(
        { width: logo.width, height: logo.height },
        { width: w, height: h },
      ),
  );

export default MobileMainLogo;
