import ClassNames from "classnames";
import { FunctionComponent } from "react";

interface Props {
  className?: string;
  svgClassName?: string;
  viewBox: string;
  symbolIdPrefix: string;
  glyph: string;
  spriteFileName: string;
}

const SvgUseIcon: FunctionComponent<Props> = ({
  viewBox,
  className,
  svgClassName,
  glyph,
  symbolIdPrefix,
  spriteFileName,
}) => {
  const id = `#${symbolIdPrefix}-${glyph}`;
  const externalPath = `/svg-sprite/${spriteFileName}${id}`;

  return (
    <span className={ClassNames("Icon", className)}>
      <svg className={svgClassName} viewBox={viewBox}>
        <use xlinkHref={externalPath} />
      </svg>
    </span>
  );
};

export default SvgUseIcon;
