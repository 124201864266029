import ClassNames from "classnames";
import { EmblaCarouselType } from "embla-carousel";
import { FunctionComponent, useState } from "react";
import { style } from "typestyle";
import {
  ColorScheme,
  ContainerQueries,
  HighlightsAspectRatio,
  HighlightsDisplayType,
  HighlightsItem,
  TitleAndSubtitleTextAlign,
} from "../types/index.js";
import CroppedImage from "./CroppedImage.js";
import HighlightHeading from "./HighlightHeading.js";
import ModuleHeadings from "./ModuleHeadings.js";
import Slider from "./Slider.js";
import SliderArrow from "./SliderArrow.js";

interface Props {
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  isFirstOnPage: boolean;
  scheme: ColorScheme;
  items: HighlightsItem[];
  queries: ContainerQueries;
  displayType: HighlightsDisplayType;
  activeModuleId: string | undefined;
  isPreview: boolean;
  transparentSliderArrowBackground: boolean;
}

const HighlightsLayout4: FunctionComponent<Props> = ({
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  scheme,
  items,
  queries,
  displayType,
  activeModuleId,
  isPreview,
  transparentSliderArrowBackground,
}) => {
  const [slider, setSlider] = useState<EmblaCarouselType>();
  const itemsCount = items.length;
  const isSliderEnabled =
    displayType === "slider" && getShouldEnableSlider(queries, itemsCount);
  const hasHighlightsTitle = !!title;

  const highlights = items.map(
    ({ id, title, subtitle, picture, imageDetail, buttons }, index) => (
      <article key={id} className="HighlightsLayout4__Item">
        <div className="HighlightsLayout4__ItemPicture">
          <CroppedImage
            aspectRatio={getAspectRatio(queries)}
            picture={picture}
            width={1280}
            detail={imageDetail}
            sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
          />
          {buttons[0]}
        </div>

        <HighlightHeading
          className="HighlightsLayout4__ItemContent"
          title={{
            value: title,
            children: title,
            className: ClassNames(
              "HighlightsLayout4__ItemTitle",
              style({ color: scheme.main.title }),
            ),
          }}
          subtitle={{
            value: subtitle,
            className: ClassNames(
              "HighlightsLayout4__ItemSubtitle",
              style({ color: scheme.main.title }),
            ),
          }}
          highlightIndex={index}
          hasHighlightsTitle={hasHighlightsTitle}
          isFirstOnPage={isFirstOnPage}
        />
      </article>
    ),
  );

  return (
    <div
      className={ClassNames(
        "HighlightsLayout4",
        style({ background: scheme.main.separator, color: scheme.main.text }),
      )}
    >
      <div className="Module__Wrapper Module__Wrapper--small-padding">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
        <div
          className={ClassNames(
            "HighlightsLayout4__Items",
            `HighlightsLayout4__Items--count-${itemsCount}`,
          )}
        >
          {isSliderEnabled && (
            <Slider
              className="HighlightsLayout4__Slider"
              containerClassName="HighlightsLayout4__SliderContainer"
              slideClassName="HighlightsLayout4__Slide"
              isPreview={isPreview}
              sliderEffect="slide"
              onSliderLoaded={setSlider}
              slidesToShow={getSlidesToShow(queries)}
              activePreviewSlideId={activeModuleId}
              slideIds={items.map(({ id }) => id)}
            >
              {highlights}
            </Slider>
          )}
          {!isSliderEnabled && (
            <div className="HighlightsLayout4__List">{highlights}</div>
          )}
          {isSliderEnabled && (
            <div className="HighlightsLayout4__SliderArrows">
              <SliderArrow
                className="HighlightsLayout4__SliderArrow HighlightsLayout4__SliderArrow--left"
                iconClassName="HighlightsLayout4__SliderArrowIcon"
                direction="left"
                onClick={() => slider?.scrollPrev()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
              <SliderArrow
                className="HighlightsLayout4__SliderArrow HighlightsLayout4__SliderArrow--right"
                iconClassName="HighlightsLayout4__SliderArrowIcon"
                direction="right"
                onClick={() => slider?.scrollNext()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getShouldEnableSlider = (
  queries: ContainerQueries,
  highlightsCount: number,
) => {
  const isXLarge = queries["Query--xlarge"];
  const isLarge = queries["Query--large"];

  return (
    (isXLarge && highlightsCount > 3) ||
    (!isXLarge && isLarge && highlightsCount > 2) ||
    (!isLarge && highlightsCount > 1)
  );
};

const getSlidesToShow = (queries: ContainerQueries) =>
  queries["Query--xlarge"] ? 3 : queries["Query--large"] ? 2 : 1;

const getAspectRatio = (queries: ContainerQueries): HighlightsAspectRatio => {
  if (queries["Query--xlarge"]) return 0.8333;
  if (queries["Query--medium"]) return 1.3333;
  return 0.8333;
};

export default HighlightsLayout4;
