import { FunctionComponent, ReactNode, useState } from "react";
import isInt from "validator/lib/isInt.js";
import { getHtmlFieldId } from "../utils/utils.js";

interface Props {
  value: number | undefined;
  allowEmpty: boolean;
  label: string;
  min: number;
  max: number;
  onChange: (value: number | undefined) => void;
  errorMessage: string;
  isValid?: boolean;
  forceUpdateValue?: () => number | undefined;
  children?: ReactNode;
}

const checkValidity = ({
  value,
  min,
  max,
  allowEmpty,
}: {
  value: string;
  min: number;
  max: number;
  allowEmpty: boolean;
}) => (allowEmpty && value === "") || isInt(value, { min, max });

const NumberFormField: FunctionComponent<Props> = ({
  label,
  min,
  max,
  onChange,
  errorMessage,
  isValid: isValidProp = true,
  value,
  allowEmpty,
  forceUpdateValue,
  children,
}) => {
  const [{ formValue, isValidState }, setState] = useState({
    formValue: value?.toString() ?? "",
    isValidState: true,
  });

  const forcedValue = forceUpdateValue?.();
  if (forcedValue !== undefined) {
    const value = forcedValue.toString();
    setState({
      formValue: value,
      isValidState: checkValidity({ allowEmpty, max, min, value }),
    });
  }

  const htmlId = getHtmlFieldId(label);

  return (
    <div className="Form__Field">
      <input
        id={htmlId}
        type="number"
        min={min}
        max={max}
        value={formValue}
        onChange={(e) => {
          const currentStringValue = e.target.value;
          const currentValue = isNaN(parseInt(currentStringValue))
            ? undefined
            : parseInt(currentStringValue);

          const newIsValid = checkValidity({
            value: currentStringValue,
            max,
            min,
            allowEmpty,
          });

          setState({
            formValue: currentValue === undefined ? "" : currentStringValue,
            isValidState: newIsValid,
          });
          newIsValid && onChange(currentValue);
        }}
      />
      <div className="Form__Label">
        <label htmlFor={htmlId}>{label}</label>
      </div>
      {(!isValidState || !isValidProp) && (
        <div className="Form__Error">{errorMessage}</div>
      )}
      {children}
    </div>
  );
};

export default NumberFormField;
