import { ResizeObserver } from "@juggle/resize-observer";
import { ResizeObserverCallback } from "@juggle/resize-observer/lib/ResizeObserverCallback";
import ClassNames from "classnames";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { throttle } from "throttle-debounce";
import { style } from "typestyle";
import { SiteLanguage } from "../../../server/types/index.js";
import { getActionModules } from "../../actions/Modules.js";
import { getPictureById } from "../../selectors/pictures.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  Accommodation,
  ActionLinks,
  AnchorAttrs,
  BaseModuleProps,
  ColorScheme,
  CommonHeaderLayoutProps,
  HeaderModuleSettings,
  ImagesModuleSettings,
  Language,
  MainMenuItem,
  PageState,
  Picture,
  StoreState,
  TranslatedModule,
} from "../../types/index.js";
import {
  buildBreadcrumb,
  checkIsOnePager,
  getActionLinks,
  getActiveColorScheme,
  getActiveLogoId,
  getActivePagePath,
  getFallbackLanguage,
  getInlineSize,
  getMainPageURL,
  getNearestHeaderImagesModule,
  getTopLevelMainMenu,
  getWideMenuBreakpoint,
} from "../../utils/utils.js";
import Breadcrumb from "../Breadcrumb.js";
import CompactHeaderBar from "../CompactHeaderBar.js";
import FixedHeader from "../FixedHeader.js";
import HeaderLayout1 from "../HeaderLayout1.js";
import HeaderLayout2 from "../HeaderLayout2.js";
import HeaderLayout3 from "../HeaderLayout3.js";
import HeaderLayout4 from "../HeaderLayout4.js";
import HeaderLayout5 from "../HeaderLayout5.js";

type Props = BaseModuleProps<HeaderModuleSettings>;

interface StateProps {
  mainPageURL: string | undefined;
  accommodation: Accommodation | undefined;
  scheme: ColorScheme;
  siteLanguages: SiteLanguage[];
  pages: PageState;
  imagesModule: TranslatedModule<ImagesModuleSettings> | undefined;
  activePagePath: string[];
  breadcrumb: AnchorAttrs[];
  fallbackLanguageId: Language | undefined;
  actionLinks: ActionLinks;
  isOnePager: boolean;
  menuItems: MainMenuItem[];
  logo: Picture;
  wideMenuBreakpoint: number;
}

type ReduxProps = ConnectedProps<typeof connector>;

const HeaderModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  isPreview,
  imagesModule,
  activePagePath,
  pageId,
  queries,
  translatedModule: {
    siteId,
    settings: {
      showBreadcrumb,
      sticky,
      menuVariant,
      showSouthTyrolLogo,
      layoutVariant,
      topHeaderVariant,
      logoSize,
      logoBackground,
      imageOverlayGradient: imageOverlayGradientFromProps,
      showMenuSeparators,
      whatsAppNumber,
    },
    translation: { languageId },
  },
  accommodation,
  fallbackLanguageId,
  mainPageURL,
  breadcrumb,
  activeModuleId,
  getActionModules,
  actionLinks,
  isOnePager,
  menuItems,
  pages,
  logo,
  wideMenuBreakpoint,
}) => {
  const [wideIntersectionEl, setWideIntersectionEl] =
    useState<HTMLElement | null>(null);

  const wideMenuFits = useWideMenuFits(wideMenuBreakpoint, isPreview);

  useEffect(() => {
    getActionModules(siteId);
  }, []);

  const showCompactHeader =
    (!imagesModule || !imagesModule.pageId) &&
    layoutVariant !== "all-in-header" &&
    layoutVariant !== "fixed-sidebar";

  const showFixedHeader = layoutVariant !== "fixed-sidebar";
  const imageOverlayGradient =
    logoBackground === "transparent" ? imageOverlayGradientFromProps : "dark";
  const isLayout3 = layoutVariant === "full-overlay-nav";

  if (!accommodation) return null;

  const layoutProps: CommonHeaderLayoutProps = {
    accommodation,
    actionLinks,
    activeModuleId,
    activePagePath,
    fallbackLanguageId,
    imageOverlayGradient,
    imagesModule,
    isPreview,
    languageId,
    logo,
    logoSize,
    logoBackground,
    mainPageURL,
    menuItems,
    pageId,
    pages,
    queries,
    scheme,
    showSouthTyrolLogo,
    sticky,
    whatsAppNumber,
  };

  return (
    <header
      className={ClassNames(
        "Module",
        "HeaderModule",
        style({ background: scheme.main.background, color: scheme.main.text }),
      )}
    >
      {showFixedHeader && (
        <FixedHeader
          topHeaderVariant={topHeaderVariant}
          actionLinks={actionLinks}
          activePagePath={activePagePath}
          hasHeaderImage={!!imagesModule}
          isPreview={isPreview}
          languageId={languageId}
          pages={pages}
          topLevelMainMenuItems={menuItems}
          scheme={scheme}
          stickyEnabled={sticky}
          fallbackLanguageId={fallbackLanguageId}
          pageId={pageId}
          mainPageURL={mainPageURL}
          logo={logo}
          logoSize={logoSize}
          intersectionTriggerEl={wideMenuFits ? wideIntersectionEl : null}
          accommodation={accommodation}
          isCircleHamburger={isLayout3}
        />
      )}

      {showCompactHeader && (
        <div
          ref={setWideIntersectionEl}
          className="HeaderModule__Inner HeaderModule__Inner--wide"
        >
          <CompactHeaderBar
            actionLinks={actionLinks}
            activePagePath={activePagePath}
            hasHeaderImage={false}
            isPreview={isPreview}
            languageId={languageId}
            pages={pages}
            topLevelMainMenuItems={menuItems}
            scheme={scheme}
            fallbackLanguageId={fallbackLanguageId}
            pageId={pageId}
            mainPageURL={mainPageURL}
            logo={logo}
            logoSize={logoSize}
            accommodation={accommodation}
            isCircleHamburger={isLayout3}
          />
        </div>
      )}

      {layoutVariant === "all-in-header" && (
        <HeaderLayout1
          intersectionTriggerRef={setWideIntersectionEl}
          topHeaderVariant={topHeaderVariant}
          common={layoutProps}
          logoSize={logoSize}
        />
      )}

      {layoutVariant === "split-menu" && (
        <HeaderLayout2
          intersectionTriggerRef={setWideIntersectionEl}
          variant={menuVariant}
          common={layoutProps}
        />
      )}

      {isLayout3 && (
        <HeaderLayout3
          intersectionTriggerRef={setWideIntersectionEl}
          common={layoutProps}
        />
      )}

      {layoutVariant === "left-overlay-nav" && (
        <HeaderLayout4
          intersectionTriggerRef={setWideIntersectionEl}
          showMenuSeparators={showMenuSeparators}
          common={layoutProps}
        />
      )}

      {layoutVariant === "fixed-sidebar" && <HeaderLayout5 {...layoutProps} />}

      {!isOnePager && showBreadcrumb && (
        <Breadcrumb
          links={breadcrumb}
          isPreview={isPreview}
          languageId={languageId}
        />
      )}
    </header>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  {
    sites,
    accommodation,
    pages,
    modules,
    colorSchemes,
    mediaLibrary: { pictures, logoIds },
  },
  {
    isPreview,
    pageId,
    translatedModule: {
      translation: { languageId },
      settings: { topHeaderVariant, layoutVariant, logoId },
      colorSchemeId,
    },
  },
): StateProps => {
  const site = getActiveSite(sites);
  const fallbackLanguageId = getFallbackLanguage(site, languageId);
  const imagesModule = getNearestHeaderImagesModule({
    pages,
    modules,
    currentPageId: pageId,
    languageId,
  });

  const menuItems = getTopLevelMainMenu({
    pages,
    languageId,
    isPreview,
    fallbackLanguageId,
  });

  const actionLinks = getActionLinks({
    isPreview,
    languageId,
    modules,
    pages,
    site,
  });

  return {
    mainPageURL: getMainPageURL(pages, languageId, isPreview),
    accommodation: accommodation[languageId],
    scheme: getActiveColorScheme(colorSchemes, site, { colorSchemeId }),
    siteLanguages: site.languages,
    pages,
    breadcrumb: buildBreadcrumb(pages, site, pageId, languageId, isPreview),
    imagesModule,
    activePagePath: getActivePagePath(pageId, pages.byParentId),
    fallbackLanguageId,
    actionLinks,
    isOnePager: checkIsOnePager(site),
    menuItems,
    logo: getPictureById(pictures, getActiveLogoId(logoIds, logoId)),
    wideMenuBreakpoint: getWideMenuBreakpoint({
      actionLinks,
      topHeaderVariant,
      layoutVariant,
      menuItems,
      hasHeaderImage: !!imagesModule,
    }),
  };
};

const mapDispatchToProps = { getActionModules };

// Check if the wide/compact menu fits
// and set classNames to <body> accordingly.
const useWideMenuFits = (
  wideMenuBreakpoint: number,
  isPreview: boolean,
): boolean => {
  const observer = useRef<ResizeObserver>();

  const wideMenuFitsClassName = "HeaderModule--wide-menu-fits";

  const [wideMenuFits, setWideMenuFits] = useState(
    typeof document !== "undefined"
      ? document.body.classList.contains(wideMenuFitsClassName)
      : false,
  );

  useEffect(
    () => () => {
      observer.current?.disconnect();
    },
    [],
  );

  useEffect(() => {
    // The body class is modified here to avoid
    // FOUC on the published site (the generator adds this class to <body>)
    document.body.classList.toggle(wideMenuFitsClassName, wideMenuFits);
  }, [wideMenuFits]);

  useEffect(() => {
    observer.current?.disconnect();

    const onResize: ResizeObserverCallback = (entries) => {
      // Needs to be `window.innerWidth` on generated sites to match
      // the behaviour of `window.matchMedia`
      const width = isPreview ? getInlineSize(entries) : window.innerWidth;
      if (width === undefined) return;
      setWideMenuFits(width >= wideMenuBreakpoint);
    };

    const onResizeThrottled = throttle(200, onResize);
    observer.current = new ResizeObserver(onResizeThrottled);
    // TODO: better get the element the “React way” with refs
    const element =
      document.querySelector(".Page__Preview__Inner") ?? document.body;
    element && observer.current.observe(element);
  }, [wideMenuBreakpoint]);

  return wideMenuFits;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(HeaderModule);
