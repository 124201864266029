import { APISite } from "../../server/types/index.js";
import { SiteState } from "../types/index.js";

export const getActiveSite = (sites: SiteState): APISite => {
  const { activeSiteId, byId } = sites;
  const site = activeSiteId !== undefined ? byId[activeSiteId] : undefined;

  if (!site) {
    throw Error("No active site found. This is most likely an error!");
  }

  return site;
};

export const getPrimaryDomain = (site: APISite): string => {
  if (!site.domains[0]) {
    throw Error("No primary domain found.");
  }

  return site.domains[0];
};

export const getAliasDomains = (site: APISite) => site.domains.slice(1);
