import { ResizeObserver } from "@juggle/resize-observer";
// Polyfills
import "intersection-observer";
import "lazysizes";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { setStylesTarget } from "typestyle";
import Generator from "./components/Generator.js";
import { getPageModules } from "./selectors/modules.js";
import configureStore from "./store/configureStore.js";
import "./stylesheets/client.scss";
import { PreloadedState, SystemPageModuleType } from "./types/index.js";
import { registerErrorReporting } from "./utils/errorReporting.js";
import { mostModulesByType } from "./utils/mostModulesByType.js";
import { getTranslatedPage } from "./utils/utils.js";

registerErrorReporting();

/**
 * Update the scroll position if there are DOM changes
 * (e.g. the Booking Südtirol Widget has loaded),
 * otherwise it would cause sudden jumps to
 * another scroll position when navigating to a hash link.
 * If the user makes an interaction (e.g. clicking, typing),
 * the scroll position update is aborted.
 */
const maintainAnchorScroll = (idToScrollTo: string) => {
  if (!idToScrollTo) return;

  const observer = new ResizeObserver(() => {
    // Use an attribute selector, not an ID selector to make sure it
    // works with IDs that begin with a number. The target element needs
    // to be selected each time in this callback because
    // the DOM could have changed after each call.
    document.querySelector(`[id="${idToScrollTo}"]`)?.scrollIntoView(true);
  });

  const interactionEventNames: (keyof WindowEventMap)[] = [
    "blur",
    "click",
    "input",
    "keydown",
    "resize",
    "wheel",
    "touchstart",
  ];

  const onInteraction = () => {
    observer.disconnect();
    interactionEventNames.forEach((name) =>
      window.removeEventListener(name, onInteraction),
    );
  };

  interactionEventNames.forEach((name) =>
    window.addEventListener(name, onInteraction, { passive: true }),
  );
  observer.observe(document.body);

  // Timeout if loading takes too long
  setTimeout(() => {
    onInteraction();
  }, 10_000);
};

const main = async () => {
  const preloadedStateElement =
    document.querySelector<HTMLScriptElement>("#preloaded-state");
  if (!preloadedStateElement || !preloadedStateElement.textContent) {
    throw Error("#preloaded-state element not found or empty");
  }
  const preloadedState: PreloadedState = JSON.parse(
    preloadedStateElement.textContent,
  );
  const { languageId, pageId, state } = preloadedState;
  const store = configureStore(state);
  const { pages, modules } = store.getState();
  const page = getTranslatedPage(pages, pageId, languageId);
  const rootEl = document.getElementById("root");
  const root = createRoot(rootEl!);

  const pageModuleTypes = getPageModules(modules, page.id).map(
    ({ type }) => type,
  );

  const systemPageModuleTypes: SystemPageModuleType[] = [
    "ImprintModule",
    "PrivacyModule",
    "TermsModule",
  ];

  const includesSystemPageModule = systemPageModuleTypes.some((moduleType) =>
    pageModuleTypes.includes(moduleType),
  );

  const modulesByType = {
    ...mostModulesByType,
    ...(includesSystemPageModule
      ? (await import("./utils/systemPageModulesByType.js"))
          .systemPageModulesByType
      : {}),
  };

  // TODO: change this to hydrate().
  // Warning: every component on the site needs to be checked afterwards
  // if it still works correctly.
  root.render(
    <Provider store={store}>
      <Generator modulesByType={modulesByType} page={page} />
    </Provider>,
  );

  const stylesTarget = document.querySelector("#typestyle-target");
  stylesTarget && setStylesTarget(stylesTarget);

  window.addEventListener("hashchange", () => {
    maintainAnchorScroll(location.hash.slice(1));
  });

  maintainAnchorScroll(location.hash.slice(1));
};

main();
