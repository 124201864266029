import ClassNames from "classnames";
import { FunctionComponent, useEffect, useRef } from "react";
import {
  EnquiryWidgetSettings,
  WidgetInstance,
  WindowState,
} from "../types/index.js";
import HgvWidgetContainer from "./HgvWidgetContainer.js";

interface Props {
  className?: string;
  settings: EnquiryWidgetSettings;
}

const EnquiryWidget: FunctionComponent<Props> = ({ className, settings }) => {
  const ref = useRef<HTMLDivElement>(null);
  const widgetInstanceRef = useRef<WidgetInstance>();

  const initWidget = () => {
    if (!ref.current) return;

    const { BookingSüdtirol } = window as unknown as WindowState;
    widgetInstanceRef.current = BookingSüdtirol.Widgets.Enquiry(
      ref.current,
      settings,
    );
    widgetInstanceRef.current.render();
  };

  useEffect(() => {
    return () => {
      widgetInstanceRef.current?.unmount();
    };
  }, []);

  return (
    <div className={ClassNames("Module__Wrapper", className)}>
      <HgvWidgetContainer
        widgetInstance={widgetInstanceRef.current}
        initWidget={initWidget}
        settings={settings}
        enableLazyload={true}
      >
        <div className="EnquiryWidget__Container" ref={ref} />
      </HgvWidgetContainer>
    </div>
  );
};

export default EnquiryWidget;
