import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getBookingFieldSettings } from "../../selectors/modules.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  BookingModuleSettings,
  BookingWidgetSettings,
  LegalNavId,
  StoreState,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getFallbackLanguage,
  getSupportedLanguage,
  resolveLink,
} from "../../utils/utils.js";
import BookingWidget from "../BookingWidget.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

interface Props extends BaseModuleProps<BookingModuleSettings> {}

interface StateProps {
  settings: BookingWidgetSettings;
}

type ReduxProps = ConnectedProps<typeof connector>;

const BookingModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule: {
    id,
    settings: { textAlign },
    translation: {
      settings: { title, subtitle },
    },
  },
  settings,
  settings: { colorScheme: scheme },
  isFirstOnPage,
}) => {
  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="BookingModule"
      colors={{ background: scheme.main.background }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>
      <BookingWidget settings={settings} />
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites, modules, pages },
  {
    translatedModule,
    translatedModule: {
      translation: { languageId },
      settings: { defaultOfferList, layout, license },
    },
    isPreview,
  },
): StateProps => {
  const site = getActiveSite(sites);
  const fallbackLanguageId = getFallbackLanguage(site, languageId);
  const getURL = (legalNavId: LegalNavId) =>
    resolveLink({
      fallbackLanguageId,
      isPreview,
      languageId,
      pageId: pages.byAlias[legalNavId],
      pages,
    }).href;

  return {
    settings: {
      propertyId: site.accommodation.id,
      lang: getSupportedLanguage(languageId, ["de", "it", "en", "fr"]),
      layout,
      defaultOfferList,
      license,
      colorScheme: getActiveColorScheme(colorSchemes, site, translatedModule),
      fieldSettings: getBookingFieldSettings(modules, languageId),
      termsURL: getURL("terms"),
      privacyURL: getURL("privacy"),
    },
  };
};

const connector = connect(mapStateToProps);

export default connector(BookingModule);
