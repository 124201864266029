import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { Accommodation, ActionLinks, Language } from "../types/index.js";
import { getContactLinks, getQuickLinksWithIcon } from "../utils/utils.js";
import Icon from "./Icon.js";

interface Props {
  accommodation: Accommodation | undefined;
  actionLinks: ActionLinks;
  className?: string;
  languageId: Language;
}

const ContactNav: FunctionComponent<Props> = ({
  accommodation,
  className,
  actionLinks,
  languageId,
}) => {
  const links = [
    ...(accommodation ? getContactLinks(accommodation, languageId) : []),
    ...getQuickLinksWithIcon(actionLinks),
  ];

  if (!links.length) return null;

  return (
    <ul className={ClassNames("ContactNav", className)}>
      {links.map(({ href, children, icon, type }) => (
        <li
          key={type}
          className={ClassNames("ContactNav__ListItem", {
            "ContactNav__ListItem--quick":
              icon === "specials" || icon === "vouchers",
          })}
        >
          <a className="ContactNav__Item" href={href}>
            <Icon className="ContactNav__Icon" glyph={icon} />
            <span className="ContactNav__ItemText">{children}</span>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default ContactNav;
