import { FunctionComponent } from "react";
import { MediaAlign } from "../types/index.js";
import FormFieldRadios from "./FormFieldRadios.js";

interface Props {
  mediaAlign: MediaAlign;
  onChange: (value: MediaAlign) => void;
}

const MediaAlignSetting: FunctionComponent<Props> = ({
  mediaAlign,
  onChange,
}) => (
  <FormFieldRadios<MediaAlign>
    denseLayout={false}
    fieldLabel="Ausrichtung"
    currentFormValue={mediaAlign}
    onChange={onChange}
    radios={[
      { value: "left", title: "Medium links", iconGlyphs: ["image", "text"] },
      { value: "right", title: "Medium rechts", iconGlyphs: ["text", "image"] },
    ]}
  />
);

export default MediaAlignSetting;
