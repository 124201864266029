import { RefObject, useEffect, useRef, useState } from "react";
import { throttle } from "throttle-debounce";

type ScrollDirection = "up" | "down";

export const useScrollDirection = (): ScrollDirection | undefined => {
  const prevScrollYRef = useRef(0);
  const [dir, setDir] = useState<ScrollDirection>();

  useEffect(() => {
    prevScrollYRef.current = window.scrollY;

    const onScroll = () => {
      const { scrollY } = window;
      const newDir = scrollY > prevScrollYRef.current ? "down" : "up";
      scrollY !== prevScrollYRef.current && setDir(newDir);
      prevScrollYRef.current = scrollY;
    };

    const onScrollThrottled = throttle(250, onScroll);

    window.addEventListener("scroll", onScrollThrottled);

    return () => {
      window.removeEventListener("scroll", onScrollThrottled);
    };
  }, []);

  return dir;
};

// Extend this list as necessary
type Key = "Escape" | "Delete" | "ArrowUp" | "ArrowDown";

export const useKeyDown = (key: Key, callback: () => void) => {
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => event.key === key && callback();
    document.addEventListener("keydown", onKeyDown, { passive: true });

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);
};

// Inspired by https://github.com/streamich/react-use/blob/master/src/useIntersection.ts
export const useIntersection = (
  ref: RefObject<HTMLElement>,
  options?: IntersectionObserverInit,
): IntersectionObserverEntry | null => {
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);

  useEffect(() => {
    if (!ref.current) {
      return () => {};
    }

    const handler = (entries: IntersectionObserverEntry[]) => {
      entries[0] && setEntry(entries[0]);
    };

    const observer = new IntersectionObserver(handler, options);
    observer.observe(ref.current);

    return () => {
      setEntry(null);
      observer.disconnect();
    };
  }, [ref.current, options?.threshold, options?.root, options?.rootMargin]);

  return entry;
};
