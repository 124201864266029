import { AllActions } from "../actions/index.js";
import { Board, Price, PriceOffers, PricesState } from "../types/index.js";
import { keys } from "../utils/utils.js";

const initialState: PricesState = { boards: [], offers: {}, byId: {} };

const getBoards = (prices: Price[]): Board[] => {
  const byBoards = prices.reduce<{ [board: number]: 1 }>((byBoards, price) => {
    keys(price.boards).forEach((board: Board) => {
      if (price.boards[board] > 0) {
        byBoards[board] = 1;
      }
    });
    return byBoards;
  }, {});

  return keys(byBoards).map<Board>((board) => <Board>+board);
};

const getOffers = (state: PriceOffers, prices: Price[]): PriceOffers =>
  prices.reduce<PriceOffers>((priceOffers, { offerId, label }) => {
    return { ...priceOffers, [offerId]: { offerId, label } };
  }, state);

const getPricesInfoSuccess = (
  state: PricesState,
  prices: Price[],
): PricesState => ({
  ...state,
  boards: getBoards(prices),
  offers: getOffers(initialState.offers, prices),
});

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "GET_PRICES_SUCCESS":
      return getPricesInfoSuccess(state, action.prices);

    default:
      return state;
  }
};

export default reducer;
