import { applyMiddleware, compose, createStore, Reducer } from "redux";
import { AllActions } from "../actions/index.js";
import { adminReducer } from "../reducers/index.js";
import storeReducer from "../reducers/store.js";
import { StoreState } from "../types/index.js";
import thunk from "../utils/redux-thunk-fixed.js";

export default function configureStore(initialState: StoreState) {
  const mainReducer: Reducer<StoreState, AllActions> = (state, action) => {
    if (action.type === "LOGOUT_USER") {
      state = initialState;
    }
    const adminReducerState = adminReducer(state, action);
    const storeReducerState = storeReducer(
      adminReducerState,
      action,
      initialState,
    );

    return storeReducerState;
  };

  // Setup for the Redux DevTools Extension
  // https://github.com/reduxjs/redux-devtools/tree/main/extension#11-basic-store
  const composeEnhancers =
    (typeof window !== "undefined" &&
      (
        window as unknown as {
          __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
        }
      ).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const store = createStore(
    mainReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
}
