import { FunctionComponent, useRef } from "react";
import {
  WeatherWidgetSettings,
  WidgetInstance,
  WindowState,
} from "../types/index.js";
import { getSupportedLanguage } from "../utils/utils.js";
import HgvWidgetContainer from "./HgvWidgetContainer.js";

interface Props {
  settings: WeatherWidgetSettings;
}

const WeatherWidget: FunctionComponent<Props> = ({ settings }) => {
  const ref = useRef<HTMLDivElement>(null);
  const widgetInstanceRef = useRef<WidgetInstance>();

  const initWidget = () => {
    const { BookingSüdtirol } = window as unknown as WindowState;
    if (!ref.current) return;

    widgetInstanceRef.current = BookingSüdtirol.Widgets.Weather(ref.current, {
      ...settings,
      lang: getSupportedLanguage(settings.lang, ["de", "it", "en"]),
    });
  };

  return (
    <HgvWidgetContainer
      widgetInstance={widgetInstanceRef.current}
      initWidget={initWidget}
      settings={settings}
      enableLazyload={true}
    >
      <div className="WeatherModule__Container" ref={ref} />
    </HgvWidgetContainer>
  );
};

export default WeatherWidget;
