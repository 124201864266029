import { Reducer } from "redux";
import { IssuesChecker } from "../../server/types/index.js";
import { AllActions } from "../actions/index.js";
import { IssueItems, IssueItemType, IssuesState } from "../types/index.js";
import { IssueNotFoundError } from "../utils/utils.js";

const initialIssues: IssueItems = { byId: {}, byType: {}, allIds: [] };

const initialChecker: IssuesChecker = {
  issues: 0,
  critical: 0,
  errors: 0,
  warnings: 0,
  archived: 0,
  resolved: 0,
  lastRun: null,
  status: "ready",
};

const initialState: IssuesState = {
  issues: initialIssues,
  checker: initialChecker,
};

const getIssuesSuccess = (
  state: IssuesState,
  response: IssueItemType[],
): IssuesState => {
  const newIssues = response.reduce<IssueItems>(
    (acc, item) => ({
      allIds: acc.allIds.concat(item.id),
      byId: { ...acc.byId, [item.id]: item },
      byType: {
        ...acc.byType,
        [item.type]: (acc.byType[item.type] || []).concat(item.id),
      },
    }),
    initialIssues,
  );

  return { ...state, issues: newIssues };
};

const patchIssueStart = (
  state: IssuesState,
  issueId: string,
  archive: boolean,
): IssuesState => {
  const currentIssue = state.issues.byId[issueId];
  if (!currentIssue) throw new IssueNotFoundError(issueId);
  const { severity, isArchived } = currentIssue;
  const sameArchivedState = isArchived === archive;
  const isNowArchived = !isArchived && archive;

  return {
    ...state,
    issues: {
      ...state.issues,
      byId: {
        ...state.issues.byId,
        [issueId]: { ...currentIssue, isArchived: archive },
      },
    },
    checker: {
      ...state.checker,
      archived:
        state.checker.archived +
        (sameArchivedState ? 0 : isNowArchived ? 1 : -1),
      errors:
        state.checker.errors +
        (sameArchivedState || severity !== "error"
          ? 0
          : isNowArchived
            ? -1
            : 1),
      issues:
        state.checker.issues + (sameArchivedState ? 0 : isNowArchived ? -1 : 1),
      warnings:
        state.checker.warnings +
        (sameArchivedState || severity !== "warning"
          ? 0
          : isNowArchived
            ? -1
            : 1),
    },
  };
};

const getIssueCheckSuccess = (
  state: IssuesState,
  response: IssuesChecker,
): IssuesState => ({ ...state, checker: response });

const issues: Reducer<IssuesState, AllActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case "GET_ISSUES_SUCCESS":
      return getIssuesSuccess(state, action.response);

    case "PATCH_ISSUE_START":
      return patchIssueStart(state, action.issueId, action.archive);

    case "GET_ISSUES_CHECK_SUCCESS":
    case "POST_ISSUES_CHECK_SUCCESS":
      return getIssueCheckSuccess(state, action.response);

    default:
      return state;
  }
};

export default issues;
