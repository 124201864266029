import { I18n } from "../types/index.js";
import en from "./en.js";

const fr: I18n = {
  boards: {
    0: "Aucun service de restauration sélectionné",
    1: "Séjour d’une nuit",
    2: "Petit-déjeuner",
    3: "Demi-pension",
    4: "Pension complète",
    5: "All inclusive",
  },
  email: "e-mail",
  phone: "téléphone",
  more: "plus",
  moreMenu: "plus",
  languageNames: {
    de: "allemand",
    it: "italien",
    en: "anglais",
    es: "espagnol",
    fr: "français",
    ru: "russe",
    da: "danois",
    pl: "polonais",
    nl: "néerlandais",
  },
  menu: "menu",
  imprint: { author: "Auteur", icons: "Icônes", pictures: "Photos" },
  errorOccurred: "Une erreur s’est produite. Veuillez réessayer plus tard.",
  countries: {
    main: "Sélection rapide",
    all: "Tous les pays",
    error: "Une erreur s'est produite lors du chargement de la liste des pays.",
  },
  genders: { m: "Monsieur", f: "Madame" },
  register: "Inscrivez vous",
  formFields: {
    gender: "Titre",
    firstname: "Prénom",
    lastname: "Nom de famille",
    email: "Adresse e-mail",
    country: "Pays",
  },
  newsletterMessages: en.newsletterMessages,
  on: en.on,
};

export default fr;
