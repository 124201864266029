import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { style } from "typestyle";
import { ColorScheme, QuoteLayout } from "../../types/index.js";
import Icon from "../Icon.js";

interface Props {
  type: "open" | "close";
  layout: QuoteLayout;
  scheme: ColorScheme;
}

const QuoteIcon: FunctionComponent<Props> = ({ type, layout, scheme }) =>
  layout === "layout-3" ? null : (
    <div className="QuoteModule__IconContainer">
      <Icon
        glyph={type === "open" ? "quotes-open" : "quotes-close"}
        className={ClassNames(
          "QuoteModule__Icon",
          `QuoteModule__Icon--${type}`,
          layout === "layout-2" &&
            style({ backgroundColor: scheme.main.background }),
        )}
      />
    </div>
  );

export default QuoteIcon;
