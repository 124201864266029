import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { Language, MainMenuItem, PageState } from "../types/index.js";
import { getMenuItems } from "../utils/utils.js";
import MainMenuListLeftNav from "./MainMenuListLeftNav.js";
import SmartLink from "./SmartLink.js";

interface Props {
  isPreview: boolean;
  languageId: Language;
  parentId: string;
  pages: PageState;
  currentMenuItem: MainMenuItem;
  menuLevel: number;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  onMenuItemClick: () => void;
}

const MainMenuLevelLeftNav: FunctionComponent<Props> = ({
  isPreview,
  languageId,
  parentId,
  pages,
  currentMenuItem,
  menuLevel,
  activePagePath,
  fallbackLanguageId,
  onMenuItemClick,
}) => {
  const menuItems = getMenuItems({
    pages,
    pageId: parentId,
    languageId,
    isPreview,
    fallbackLanguageId,
  });
  const { title, href, id, isFolder, target } = currentMenuItem;
  const isActive = activePagePath[0] === id;

  const listItemClassName = ClassNames("MainMenu__ListItem", {
    MainMenu__ListItemLevel1: menuLevel === 1,
    MainMenu__ListItemLevel2up: menuLevel >= 2,
  });

  const linkItemClassName = ClassNames("MainMenu__Item", {
    MainMenu__ItemLevel0: menuLevel === 0,
    MainMenu__ItemLevel1: menuLevel === 1,
    MainMenu__ItemLevel2up: menuLevel >= 2,
    "MainMenu__Item--active": isActive,
  });

  const nextMenuLevel = menuLevel + 1;

  return (
    <li className={listItemClassName}>
      <div className="MainMenu__ItemWrap">
        <SmartLink
          isPreview={isPreview}
          anchorAttrs={{
            href: !isPreview && isFolder ? undefined : href,
            target,
            className: linkItemClassName,
            onClick: onMenuItemClick,
          }}
        >
          <span className="MainMenu__Item__Text">{title}</span>
        </SmartLink>
      </div>

      <MainMenuListLeftNav
        isPreview={isPreview}
        languageId={languageId}
        menuItems={menuItems}
        pages={pages}
        menuLevel={nextMenuLevel}
        activePagePath={activePagePath}
        fallbackLanguageId={fallbackLanguageId}
        onMenuItemClick={onMenuItemClick}
      />
    </li>
  );
};

export default MainMenuLevelLeftNav;
