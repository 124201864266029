import ClassNames from "classnames";
import deepEqual from "fast-deep-equal";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import isURL from "validator/lib/isURL.js";
import { injectScript } from "../../actions/LoadStates.js";
import { getActiveSite, getPrimaryDomain } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  LoadStatus,
  StoreState,
  VideoModuleSettings,
  WindowState,
} from "../../types/index.js";
import {
  convertTimeToSeconds,
  getActiveColorScheme,
  getYouTubeVideoId,
} from "../../utils/utils.js";
import Icon from "../Icon.js";
import LazyloadWrapper from "../LazyloadWrapper.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import UsercentricsCMPWrapper from "../UsercentricsCMPWrapper.js";

const youTubeScriptUrl = "https://www.youtube.com/iframe_api";

interface Props extends BaseModuleProps<VideoModuleSettings> {}
interface StateProps {
  scheme: ColorScheme;
  loadStatus: LoadStatus;
  domain: string;
}
type ReduxProps = ConnectedProps<typeof connector>;

type YouTubeSettings = Omit<VideoModuleSettings["global"], "textAlign">;

const VideoModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule: {
    id,
    settings,
    settings: { textAlign, ...youTubeSettings },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  scheme,
  isActive,
  isPreview,
  isFirstOnPage,
  injectScript,
  loadStatus,
  domain,
}) => {
  const iFrameRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<YT.Player>();
  const youTubeSettingsRef = useRef<YouTubeSettings>(youTubeSettings);
  youTubeSettingsRef.current = youTubeSettings;
  const prevYouTubeSettingsRef = useRef<YouTubeSettings>(youTubeSettings);
  const prevHasTitleOrSubtitle = useRef(Boolean(title || subtitle));
  const isDestroyedRef = useRef(false);

  const [isLazyloaded, setIsLazyloaded] = useState(false);
  const [consentIsGiven, setConsentIsGiven] = useState(isPreview);

  const initVideo = () => {
    const windowState = window as unknown as WindowState;
    if (!iFrameRef.current || !windowState.YT?.Player) return;

    const { videoUrl, startAt, showInfo, controls } =
      youTubeSettingsRef.current;

    const videoId = getYouTubeVideoId(videoUrl);
    const list = isURL(videoUrl)
      ? (new URL(videoUrl).searchParams.get("list") ?? undefined)
      : undefined;

    isDestroyedRef.current = false;

    playerRef.current = new windowState.YT.Player(iFrameRef.current, {
      videoId,
      height: 390,
      width: 640,
      playerVars: {
        listType: list ? "playlist" : undefined,
        list,
        start: convertTimeToSeconds(startAt),
        modestbranding: 1,
        showinfo: +showInfo,
        controls: +controls,
        rel: 0,
        iv_load_policy: 3,
        // TODO: still throws an error about invalid origin
        origin: `https://${domain}`,
      },
      events: {
        onReady: () => {
          youTubeSettingsRef.current.mute && playerRef.current?.mute();
          youTubeSettingsRef.current.autoPlay && playerRef.current?.playVideo();
        },
      },
    });
  };

  // Youtube API takes some time after script loading to be available
  const isApiReady = () => {
    const windowState = window as unknown as WindowState;

    const interval = setInterval(() => {
      if (windowState.YT && windowState.YT.Player) {
        initVideo();
        clearInterval(interval);
      }
    }, 100);
  };

  const loadYouTube = () => {
    injectScript(youTubeScriptUrl);
    loadStatus === "loaded" && isApiReady();
  };

  useEffect(() => {
    isPreview && isActive && loadYouTube();
  }, [isPreview, isActive]);

  useEffect(() => {
    !isPreview && !isActive && consentIsGiven && isLazyloaded && loadYouTube();
  }, [isPreview, isActive, isLazyloaded, consentIsGiven]);

  // On unmount
  useEffect(
    () => () => {
      !isDestroyedRef.current && playerRef.current?.destroy();
    },
    [],
  );

  useEffect(() => {
    loadStatus === "loaded" && isApiReady();
  }, [loadStatus]);

  useEffect(() => {
    const hasTitleOrSubtitle = Boolean(title || subtitle);
    const settingsHaveChanged =
      !deepEqual(youTubeSettings, prevYouTubeSettingsRef.current) ||
      prevHasTitleOrSubtitle.current !== hasTitleOrSubtitle;

    if (settingsHaveChanged && !isDestroyedRef.current) {
      playerRef.current?.destroy();
      isDestroyedRef.current = true;
    }
    settingsHaveChanged && initVideo();

    prevYouTubeSettingsRef.current = youTubeSettings;
    prevHasTitleOrSubtitle.current = hasTitleOrSubtitle;
  }, [youTubeSettings, title, subtitle]);

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="VideoModule"
      colors={{ background: scheme.main.separator }}
    >
      <div
        className={ClassNames("VideoModule__Wrapper Module__Wrapper", {
          "VideoModule__Wrapper--small": settings.size === "small",
          "VideoModule__Wrapper--full-width": settings.size === "fullWidth",
          "VideoModule__Wrapper--no-click": !isActive && isPreview,
        })}
      >
        <div className="Module__Wrapper">
          <ModuleHeadings
            className="VideoModule__Title"
            scheme={scheme}
            isFirstOnPage={isFirstOnPage}
            textAlign={textAlign}
            title={title}
            subtitle={subtitle}
          />
        </div>

        <LazyloadWrapper onLoad={setIsLazyloaded}>
          <UsercentricsCMPWrapper
            languageId={languageId}
            serviceName="YouTube Video"
            onConsentGiven={() => setConsentIsGiven(true)}
            consentIsGiven={consentIsGiven}
          >
            <div className="VideoModule__Wrapper__Inner">
              <div ref={iFrameRef} className="VideoModule__Video">
                <div className="VideoModule__Placeholder">
                  <Icon
                    className="VideoModule__PlaceholderIcon"
                    glyph="video"
                  />
                </div>
              </div>
            </div>
          </UsercentricsCMPWrapper>
        </LazyloadWrapper>
      </div>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, loadStates, sites },
  { translatedModule: module },
): StateProps => {
  const site = getActiveSite(sites);

  return {
    domain: getPrimaryDomain(site),
    scheme: getActiveColorScheme(colorSchemes, site, module),
    loadStatus: loadStates.scripts[youTubeScriptUrl] || "unloaded",
  };
};

const mapDispatchToProps = { injectScript };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(VideoModule);
