import ClassNames from "classnames";
import { FunctionComponent } from "react";

interface Props {
  isMenuOpen: boolean;
  onClick: () => void;
}

const CircleHamburgerButton: FunctionComponent<Props> = ({
  isMenuOpen,
  onClick,
}) => (
  <button
    type="button"
    className={ClassNames("CircleHamburgerButton", {
      "CircleHamburgerButton--open": isMenuOpen,
    })}
    onClick={onClick}
  >
    <span className="CircleHamburgerButton__Inner">
      <span className="CircleHamburgerButton__Icon">
        <span className="CircleHamburgerButton__Line CircleHamburgerButton__Line1"></span>
        <span className="CircleHamburgerButton__Line CircleHamburgerButton__Line2"></span>
        <span className="CircleHamburgerButton__Line CircleHamburgerButton__Line3"></span>
        <span className="CircleHamburgerButton__Line CircleHamburgerButton__Line4"></span>
      </span>
    </span>
  </button>
);

export default CircleHamburgerButton;
