import ClassNames from "classnames";
import { FunctionComponent } from "react";
import {
  ColorScheme,
  ImageModuleSettings,
  ImagesModuleSettings,
  TranslatedModule,
} from "../types/index.js";
import ImageModule from "./Modules/ImageModule.js";

interface Props {
  pageId: string;
  images: TranslatedModule<ImageModuleSettings>[];
  isInsideHeader: boolean;
  scheme: ColorScheme;
  isPreview: boolean;
  settings: ImagesModuleSettings["global"];
}

const ImagesSeparator: FunctionComponent<Props> = ({
  images,
  isInsideHeader,
  isPreview,
  scheme,
  settings: { columnsCount, mediaAspectRatio, gap },
  pageId,
}) => (
  <div className="ImagesSeparator">
    <div
      className={ClassNames(
        "ImagesSeparator__Inner",
        `ImagesSeparator__Inner--columns-${columnsCount}`,
        `ImagesSeparator__Inner--gap-${gap}`,
      )}
    >
      {images.map((image) => (
        <div className="ImagesSeparator__Item" key={image.id}>
          <ImageModule
            aspectRatio={mediaAspectRatio}
            translatedModule={image}
            width={1920}
            sizes={Math.round((1 / columnsCount) * 100) + "vw"}
            lazyLoad={!isInsideHeader}
            scheme={scheme}
            isPreview={isPreview}
            isSlider={false}
            pageId={pageId}
            showOverlay={true}
          />
        </div>
      ))}
    </div>
  </div>
);

export default ImagesSeparator;
