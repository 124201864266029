import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { style } from "typestyle";
import { checkIsPlaceholder } from "../selectors/pictures.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  ColorScheme,
  HeaderLogoBackground,
  Picture,
  StoreState,
  ThreeSizes,
} from "../types/index.js";
import { fitRectWidth, logoSizeMultipliers } from "../utils/utils.js";
import SmartLink from "./SmartLink.js";

interface Props {
  isPreview: boolean;
  logo: Picture;
  logoBackground?: HeaderLogoBackground;
  mainPageURL: string | undefined;
  maxSize: { width: number; height: number };
  scheme: ColorScheme;
  size: ThreeSizes;
}

interface StateProps {
  accommodationName: string | undefined;
  width: number;
  src: string;
}

type ReduxProps = ConnectedProps<typeof connector>;

const HeaderLogo: FunctionComponent<Props & ReduxProps> = ({
  accommodationName,
  isPreview,
  logo,
  logoBackground,
  mainPageURL,
  scheme,
  src,
  width,
}) =>
  checkIsPlaceholder(logo) ? null : (
    <div
      className={ClassNames(
        "HeaderModule__Logo",
        logoBackground &&
          style({
            backgroundColor:
              logoBackground === "color-scheme-background"
                ? scheme.main.background
                : logoBackground,
          }),
      )}
    >
      <SmartLink
        anchorAttrs={{ href: mainPageURL || "#" }}
        isPreview={isPreview}
      >
        <img
          className={ClassNames("HeaderModule__LogoImage", style({ width }))}
          src={src}
          title={logo.title ?? undefined}
          alt={logo.title ?? accommodationName ?? ""}
          width={logo.width}
          height={logo.height}
        />
      </SmartLink>
    </div>
  );

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { sites },
  { logo, maxSize, size },
): StateProps => {
  const width = Math.round(
    logoSizeMultipliers[size] *
      fitRectWidth({ width: logo.width, height: logo.height }, maxSize),
  );
  return {
    accommodationName: getActiveSite(sites)?.accommodation.name,
    width,
    src: logo.url,
  };
};

const connector = connect(mapStateToProps);

export default connector(HeaderLogo);
