import { Alert } from "../types/index.js";

export type Action =
  | { type: "SHOW_ALERT"; text: string; alertType: Alert["type"] | undefined }
  | { type: "REMOVE_ALERT"; id: string };

export function showAlert(text: string, alertType?: Alert["type"]): Action {
  return { type: "SHOW_ALERT", text, alertType };
}

export function removeAlert(id: string): Action {
  return { type: "REMOVE_ALERT", id };
}
