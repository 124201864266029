import ClassNames from "classnames";
import { FunctionComponent, RefCallback } from "react";
import { CommonHeaderLayoutProps, HeaderMenuVariant } from "../types/index.js";
import { getImageModuleWithFixedAspectRatio } from "../utils/utils.js";
import HeaderActionBottomBar from "./HeaderActionBottomBar.js";
import HeaderActionLinks from "./HeaderActionLinks.js";
import Header2Mobile from "./HeaderMobile/Header2Mobile.js";
import ImagesModule from "./Modules/ImagesModule.js";
import SplitMainMenu from "./SplitMainMenu.js";

interface Props {
  intersectionTriggerRef: RefCallback<HTMLElement>;
  variant: HeaderMenuVariant;
  common: CommonHeaderLayoutProps;
}

const HeaderLayout2: FunctionComponent<Props> = ({
  intersectionTriggerRef,
  variant,
  common,
  common: {
    accommodation,
    actionLinks,
    activePagePath,
    imagesModule,
    isPreview,
    languageId,
    logo,
    logoSize,
    pageId,
    queries,
    scheme,
    showSouthTyrolLogo,
  },
}) => (
  <>
    {imagesModule?.pageId && (
      <div
        className={ClassNames(
          "HeaderModule__Inner",
          "HeaderModule__Inner--wide",
          `HeaderModule__Inner--menu-position-${variant}`,
          { "HeaderModule__Inner--full-height": !isPreview },
        )}
      >
        <SplitMainMenu
          isPreview={isPreview}
          languageId={languageId}
          pageId={pageId}
          activePagePath={activePagePath}
          scheme={scheme}
          variant={variant}
          outerWrapperRef={intersectionTriggerRef}
          showSouthTyrolLogo={showSouthTyrolLogo}
          logo={logo}
          logoSize={logoSize}
        />

        <ImagesModule
          className="FullScreenHeaderImages"
          translatedModule={getImageModuleWithFixedAspectRatio(imagesModule)}
          isPreview={isPreview}
          pageId={pageId}
          queries={queries}
          isActive={false}
          isInsideHeader={true}
          isFirstOnPage={false}
          parentScheme={scheme}
          activeModuleId={undefined}
          fullHeight={!isPreview}
        />

        {variant === "top-bottom-bars" && (
          <HeaderActionBottomBar
            accommodation={accommodation}
            actionLinks={actionLinks}
            isPreview={isPreview}
            scheme={scheme}
            languageId={languageId}
          />
        )}

        {variant === "bottom-bar" && (
          <div className="HeaderModule__InnerOverlay HeaderModule__InnerOverlay--split-menu">
            <HeaderActionLinks
              languageId={languageId}
              accommodation={accommodation}
              isPreview={isPreview}
              actionLinks={actionLinks}
            />
          </div>
        )}
      </div>
    )}

    <Header2Mobile {...common} />
  </>
);

export default HeaderLayout2;
