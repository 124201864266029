import ClassNames from "classnames";
import { FunctionComponent, ReactNode } from "react";
import Icon from "./Icon.js";

interface Props {
  className?: string;
  children: ReactNode;
}

const FormInfo: FunctionComponent<Props> = ({ children, className }) => (
  <div className={ClassNames("Form__Info", className)}>
    <Icon glyph="info" /> {children}
  </div>
);

export default FormInfo;
