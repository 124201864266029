import { FunctionComponent } from "react";
import {
  HighlightsItem,
  HighlightsLayout5AspectRatio,
} from "../types/index.js";
import ConditionalLink from "./ConditionalLink.js";
import CroppedImage from "./CroppedImage.js";
import HighlightHeading from "./HighlightHeading.js";

interface Props {
  isPreview: boolean;
  item: HighlightsItem;
  aspectRatio: HighlightsLayout5AspectRatio;
  index: number;
  hasHighlightsTitle: boolean;
  isFirstOnPage: boolean;
}

const HighlightsLayout5Item: FunctionComponent<Props> = ({
  isPreview,
  item: { id, imageDetail, link, subtitle, title, picture },
  aspectRatio,
  index,
  hasHighlightsTitle,
  isFirstOnPage,
}) => (
  <ConditionalLink
    key={id}
    className="HighlightsLayout5__Item"
    anchorAttrs={link ?? {}}
    isPreview={isPreview}
    divIfNoLink={true}
  >
    <div className="HighlightsLayout5__ItemPicture">
      <CroppedImage
        aspectRatio={aspectRatio}
        picture={picture}
        width={1280}
        detail={imageDetail}
        sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
      />
    </div>

    <HighlightHeading
      className="HighlightsLayout5__ItemContent"
      title={{
        value: title,
        className: "HighlightsLayout5__ItemTitle",
        children: title,
      }}
      subtitle={{
        value: subtitle,
        className: "HighlightsLayout5__ItemSubtitle",
      }}
      highlightIndex={index}
      hasHighlightsTitle={hasHighlightsTitle}
      isFirstOnPage={isFirstOnPage}
    />
  </ConditionalLink>
);

export default HighlightsLayout5Item;
