import { FunctionComponent } from "react";
import {
  AnchorAttrs,
  AspectRatio,
  ImageDetail,
  Picture,
} from "../types/index.js";
import ConditionalLink from "./ConditionalLink.js";
import CroppedImage from "./CroppedImage.js";
import HighlightHeading from "./HighlightHeading.js";

interface Props {
  title: string;
  subtitle: string;
  link?: AnchorAttrs;
  mediaAspectRatio: AspectRatio;
  picture: Picture;
  imageDetail: ImageDetail;
  isPreview: boolean;
  tilesCount: number;
  index: number;
  hasHighlightsTitle: boolean;
  isFirstOnPage: boolean;
}

const MultiBoxTile: FunctionComponent<Props> = ({
  title,
  subtitle,
  link = {},
  picture,
  mediaAspectRatio,
  imageDetail,
  isPreview,
  tilesCount,
  index,
  hasHighlightsTitle,
  isFirstOnPage,
}) => {
  return (
    <ConditionalLink
      className="MultiBox__Tile"
      anchorAttrs={link}
      isPreview={isPreview}
      divIfNoLink
    >
      <CroppedImage
        aspectRatio={mediaAspectRatio}
        width={1280}
        picture={picture}
        detail={imageDetail}
        sizes={tilesCount > 1 ? "50vw" : "100vw"}
      />

      <HighlightHeading
        className="MultiBox__Tile__Headings"
        title={{
          value: title,
          className: "MultiBox__Tile__Title",
          children: title,
        }}
        subtitle={{ value: subtitle, className: "MultiBox__Tile__Subtitle" }}
        highlightIndex={index}
        hasHighlightsTitle={hasHighlightsTitle}
        isFirstOnPage={isFirstOnPage}
      />
    </ConditionalLink>
  );
};

export default MultiBoxTile;
