import { FunctionComponent } from "react";
import { spriteData } from "../../dist/svg-sprite/icons-data.js";
import { IconName } from "../types/index.js";
import SvgUseIcon from "./SvgUseIcon.js";

const { spriteFileName, viewBoxes, symbolIdPrefix } = spriteData;

interface Props {
  className?: string;
  glyph: IconName;
}

const Icon: FunctionComponent<Props> = ({ glyph, className }) => (
  <SvgUseIcon
    className={className}
    viewBox={viewBoxes[glyph]}
    symbolIdPrefix={symbolIdPrefix}
    glyph={glyph}
    spriteFileName={spriteFileName}
  />
);

export default Icon;
