import { FunctionComponent, useEffect, useRef } from "react";
import {
  Accommodation,
  ActionLinks,
  ColorScheme,
  Language,
  MainMenuItem,
  PageState,
  Picture,
  ThreeSizes,
  TopHeaderVariant,
} from "../types/index.js";
import ActionModuleNavigation from "./ActionModuleNavigation.js";
import ContactNav from "./ContactNav.js";
import HeaderLogo from "./HeaderLogo.js";
import LanguageMenu from "./LanguageMenu.js";
import PriorityNavigation from "./PriorityNavigation.js";

const isInHeaderNav = (topHeaderVariant: TopHeaderVariant) =>
  topHeaderVariant === "in-header-nav";

const isBelowHeaderNav = (topHeaderVariant: TopHeaderVariant) =>
  topHeaderVariant === "below-header-nav";

interface Props {
  accommodation: Accommodation | undefined;
  actionLinks: ActionLinks;
  activePagePath: string[];
  hasHeaderImage: boolean;
  isPreview: boolean;
  languageId: Language;
  topHeaderVariant: TopHeaderVariant;
  logo: Picture;
  mainPageURL: string | undefined;
  pages: PageState;
  scheme: ColorScheme;
  topLevelMainMenuItems: MainMenuItem[];
  isStaticHeader: boolean;
  fallbackLanguageId: Language | undefined;
  pageId: string;
  onRef?: (el: HTMLDivElement) => void;
  onIntersectionTriggerRef: (el: HTMLElement) => void;
  logoSize: ThreeSizes;
}

const HeaderBar: FunctionComponent<Props> = ({
  accommodation,
  actionLinks,
  activePagePath,
  hasHeaderImage,
  isPreview,
  languageId,
  topHeaderVariant,
  logo,
  logoSize,
  mainPageURL,
  pages,
  scheme,
  topLevelMainMenuItems,
  isStaticHeader,
  fallbackLanguageId,
  pageId,
  onRef,
  onIntersectionTriggerRef,
}) => {
  const topBlockElRef = useRef<HTMLDivElement>(null);
  const blockCElRef = useRef<HTMLDivElement>(null);

  const handleRef = () => {
    if (!topBlockElRef.current) return;
    isBelowHeaderNav(topHeaderVariant) &&
      onIntersectionTriggerRef(topBlockElRef.current);
    onRef && onRef(topBlockElRef.current);
  };

  const handleBlockCRef = () => {
    blockCElRef.current &&
      isInHeaderNav(topHeaderVariant) &&
      onIntersectionTriggerRef(blockCElRef.current);
  };

  useEffect(() => {
    handleRef();
    handleBlockCRef();
  }, [topHeaderVariant]);

  const languageMenu = (
    <LanguageMenu
      className="LanguageMenu--all-in-header"
      isPreview={isPreview}
      languageId={languageId}
      scheme={scheme}
      pageId={pageId}
      variant="horizontal"
    />
  );

  return (
    <div ref={topBlockElRef} className="HeaderModule__TopBlock">
      <div className="HeaderModule__Language_and_MainMenu">
        {isStaticHeader && languageMenu}
      </div>

      <HeaderLogo
        isPreview={isPreview}
        logo={logo}
        mainPageURL={mainPageURL}
        scheme={scheme}
        maxSize={{ width: 250, height: 120 }}
        size={logoSize}
      />

      <div className="HeaderModule__BlockF">
        <ActionModuleNavigation
          className="ActionModuleNavigation--block"
          isPreview={isPreview}
          actionLinks={actionLinks}
          scheme={scheme}
          showQuickLinks={true}
        />
        {isStaticHeader && (
          <div className="HeaderModule__BlockC" ref={blockCElRef}>
            {languageMenu}
            <ContactNav
              className="HeaderModule__Contact Nav"
              accommodation={accommodation}
              actionLinks={actionLinks}
              languageId={languageId}
            />
          </div>
        )}
        {isInHeaderNav(topHeaderVariant) && (
          <div className="HeaderModule_BlockA">
            <PriorityNavigation
              className="MainMenu__Wrapper--in-header-nav MainMenu__Wrapper--horizontal"
              isPreview={isPreview}
              languageId={languageId}
              menuItems={topLevelMainMenuItems}
              pages={pages}
              scheme={scheme}
              topHeaderVariant={topHeaderVariant}
              activePagePath={activePagePath}
              hasHeaderImage={hasHeaderImage}
              absolutePositioning={false}
              fallbackLanguageId={fallbackLanguageId}
            />

            <ActionModuleNavigation
              className="ActionModuleNavigation--in-header-nav"
              isPreview={isPreview}
              actionLinks={actionLinks}
              scheme={scheme}
              showQuickLinks={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderBar;
