import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import tinycolors from "tinycolor2";
import { getEnquiryFieldSettings } from "../../selectors/modules.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  EnquiryModuleSettings,
  EnquiryWidgetSettings,
  LegalNavId,
  StoreState,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getFallbackLanguage,
  getSupportedLanguage,
  resolveLink,
} from "../../utils/utils.js";
import EnquiryWidget from "../EnquiryWidget.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

interface Props extends BaseModuleProps<EnquiryModuleSettings> {}

interface StateProps {
  scheme: ColorScheme;
  settings: EnquiryWidgetSettings;
}

type ReduxProps = ConnectedProps<typeof connector>;

const EnquiryModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id: moduleId,
    settings: { textAlign },
    translation: {
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  settings,
}) => {
  // Don’t render anything before accommodation is loaded
  if (!settings.propertyId) return null;

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={moduleId}
      className="EnquiryModule"
      colors={{ background: scheme.main.separator, color: scheme.main.text }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      {<EnquiryWidget settings={settings} />}
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites, modules, pages },
  {
    translatedModule,
    translatedModule: {
      translation: { languageId },
      settings: { layout },
    },
    isPreview,
  },
): StateProps => {
  const site = getActiveSite(sites);
  const fallbackLanguageId = getFallbackLanguage(site, languageId);

  const scheme = getActiveColorScheme(colorSchemes, site, translatedModule);

  const privacyURL = resolveLink({
    fallbackLanguageId,
    isPreview,
    languageId,
    pageId: pages.byAlias["privacy" satisfies LegalNavId],
    pages,
  }).href;

  const settings: EnquiryWidgetSettings = {
    fieldSettings: getEnquiryFieldSettings(modules, languageId),
    propertyId: site.accommodation.id,
    lang: getSupportedLanguage(languageId, ["de", "it", "en", "fr"]),
    layout,
    colorScheme: {
      ...scheme,
      main: {
        ...scheme.main,
        separator: tinycolors(scheme.main.separator).lighten(2).toRgbString(),
      },
    },
    privacyURL,
  };

  return { scheme, settings };
};

const connector = connect(mapStateToProps);

export default connector(EnquiryModule);
