import { AllActions } from "../actions/index.js";
import { Revision, StoreRevisions } from "../types/index.js";

const initialState: StoreRevisions = { allIds: [], byId: {} };

const getRevisionsSuccess = (revisions: Revision[]): StoreRevisions => {
  const storeRevisions = revisions.reduce<StoreRevisions>(
    ({ allIds, byId }, revision) => {
      return {
        allIds: [...allIds, revision.id],
        byId: { ...byId, [revision.id]: revision },
      };
    },
    initialState,
  );

  return storeRevisions;
};

const patchRevisionSuccess = (
  state: StoreRevisions,
  revisionId: string,
  isFavorite: boolean,
): StoreRevisions => {
  const revision = state.byId[revisionId];
  if (!revision) throw Error("Site revision not found");
  return {
    ...state,
    byId: { ...state.byId, [revisionId]: { ...revision, isFavorite } },
  };
};

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "GET_REVISIONS_SUCCESS":
      return getRevisionsSuccess(action.revisions);

    case "PATCH_REVISION_START":
      return patchRevisionSuccess(state, action.revisionId, action.isFavorite);

    default:
      return state;
  }
};

export default reducer;
