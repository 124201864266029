import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { AspectRatio } from "../types/index.js";

interface Props {
  isActive: boolean;
  isPreview: boolean;
  url: string | undefined;
  placeholder: string;
  aspectRatio?: AspectRatio;
  className?: string;
}

const EmbeddedGoogleMaps: FunctionComponent<Props> = ({
  isActive,
  isPreview,
  url,
  placeholder,
  aspectRatio,
  className,
}) => (
  <div className="EmbeddedGoogleMaps">
    {url && (
      <iframe
        scrolling="no"
        allowFullScreen={true}
        className={ClassNames("EmbeddedGoogleMaps__Iframe", className, {
          "EmbeddedGoogleMaps__Iframe--no-click": !isActive && isPreview,
          AspectRatio: aspectRatio,
        })}
        style={
          aspectRatio
            ? ({ "--aspect-ratio": aspectRatio } as React.CSSProperties)
            : undefined
        }
        loading="lazy"
        src={url}
      />
    )}
    {!url && <div className="EmbeddedGoogleMaps__Empty">{placeholder}</div>}
  </div>
);

export default EmbeddedGoogleMaps;
