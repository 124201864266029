import { AllActions } from "../../actions/index.js";
import { getPageModuleIds } from "../../selectors/modules.js";
import { Module, ModulesByPageId, Page } from "../../types/index.js";
import { moveArrayElement } from "../../utils/utils.js";

export const initialState: ModulesByPageId = {};

const createModule = (
  state: ModulesByPageId,
  pageId: string | null,
  moduleId: string,
  parentId: string | null,
  next?: string,
): ModulesByPageId => {
  // If the module should not be shown
  // directly on the page, but as a nested module
  if (parentId) return state;

  return updateModules(state, pageId, (modules) => {
    // If no next moduleId is set, append it to the end.
    if (!next) {
      return [...modules, moduleId];
    }

    const nextIndex = modules.indexOf(next);

    if (nextIndex === -1) {
      throw new Error(`The next module (${next}) doesn’t exist.`);
    }

    // Insert before the next module
    return [
      ...modules.slice(0, nextIndex),
      moduleId,
      ...modules.slice(nextIndex),
    ];
  });
};

const dragModule = (
  state: ModulesByPageId,
  pageId: string,
  dragIndex: number,
  hoverIndex: number,
): ModulesByPageId => {
  return updateModules(state, pageId, (modules) => {
    return moveArrayElement(modules, dragIndex, hoverIndex);
  });
};

const moveModule = ({
  state,
  pageId,
  moduleId,
  moveBy,
}: {
  state: ModulesByPageId;
  pageId: string | null;
  moduleId: string;
  moveBy: number;
}): ModulesByPageId =>
  updateModules(state, pageId, (modules) => {
    const index = modules.indexOf(moduleId);
    if (index + moveBy < 0) return modules;

    let newModules = modules.slice();
    newModules.splice(index, 1);
    newModules.splice(index + moveBy, 0, modules[index]);

    return newModules;
  });

const deleteModuleTranslation = ({
  state,
  pageId,
  moduleId,
  deleteAllTranslations,
}: {
  state: ModulesByPageId;
  pageId: string | null;
  moduleId: string;
  deleteAllTranslations: boolean;
}): ModulesByPageId => {
  if (!deleteAllTranslations) return state;

  return updateModules(state, pageId, (modules) => {
    return modules.filter((key) => key !== moduleId);
  });
};

const updateModules = (
  state: ModulesByPageId,
  pageId: string | null,
  callback: (moduleIds: string[]) => string[],
): ModulesByPageId => {
  const oldModuleIds = getPageModuleIds(state, pageId) || [];

  return { ...state, [String(pageId)]: callback(oldModuleIds) };
};

const getModules = (
  state: ModulesByPageId,
  pageId: string,
  modules: Module[],
): ModulesByPageId => {
  const fullModules = modules.filter(({ parentId }) => !parentId);

  return updateModules(state, pageId, () =>
    fullModules.map((module) => module.id),
  );
};

const getModulesFromPages = (state: ModulesByPageId, pages: Page[]) =>
  pages.reduce(
    (carryState, { modules, id: pageId }) =>
      getModules(carryState, pageId, modules),
    state,
  );

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "POST_MODULE_START":
      return createModule(
        state,
        action.pageId,
        action.moduleId,
        action.parentId,
        action.next,
      );

    case "DRAG_MODULE":
      return dragModule(
        state,
        action.pageId,
        action.dragIndex,
        action.hoverIndex,
      );

    case "MOVE_MODULE":
      return moveModule({
        state,
        pageId: action.pageId,
        moduleId: action.moduleId,
        moveBy: action.moveBy,
      });

    case "DELETE_MODULE_TRANSLATION_START":
      return deleteModuleTranslation({
        state,
        pageId: action.pageId,
        moduleId: action.moduleId,
        deleteAllTranslations: action.deleteAllTranslations,
      });

    case "GET_MODULES_SUCCESS":
      return getModules(state, action.pageId, action.modules);

    case "GET_PAGES_SUCCESS":
      return getModulesFromPages(state, action.pages);

    case "GET_MODULES_START":
    case "GET_MODULES_ERROR":
      return updateModules(state, action.pageId, () => []);

    default:
      return state;
  }
};

export default reducer;
