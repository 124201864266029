import { FunctionComponent, ReactNode } from "react";
import MultilineText from "./MultilineText.js";

interface Props {
  className?: string;
  title: { value: string; className: string; children: ReactNode };
  subtitle: { value: string; className: string };
  highlightIndex: number;
  hasHighlightsTitle: boolean;
  isFirstOnPage: boolean;
}

const HighlightHeading: FunctionComponent<Props> = ({
  className,
  title,
  subtitle,
  hasHighlightsTitle,
  highlightIndex,
  isFirstOnPage,
}) => {
  const TitleTagName = getTitleTagName({
    hasHighlightsTitle,
    highlightIndex,
    isFirstOnPage,
  });
  const SubtitleTagName = title.value ? "div" : TitleTagName;
  return title.value || subtitle.value ? (
    <header className={className}>
      {title.value && (
        <TitleTagName className={title.className}>
          {title.children}
        </TitleTagName>
      )}
      {subtitle.value && (
        <SubtitleTagName className={subtitle.className}>
          <MultilineText text={subtitle.value} />
        </SubtitleTagName>
      )}
    </header>
  ) : null;
};

const getTitleTagName = ({
  highlightIndex,
  hasHighlightsTitle,
  isFirstOnPage,
}: {
  highlightIndex: number;
  hasHighlightsTitle: boolean;
  isFirstOnPage: boolean;
}): "h1" | "h2" | "h3" => {
  if (isFirstOnPage && hasHighlightsTitle) {
    return "h2";
  }
  if (isFirstOnPage) {
    return highlightIndex ? "h2" : "h1";
  }
  return hasHighlightsTitle ? "h3" : "h2";
};

export default HighlightHeading;
