import { FunctionComponent } from "react";
import {
  BaseModuleProps,
  ColorScheme,
  OfferingsModuleSettings,
} from "../../types/index.js";
import OfferingsModule from "./OfferingsModule.js";

interface Props extends BaseModuleProps<OfferingsModuleSettings> {
  scheme?: ColorScheme;
}

const SpecialsModule: FunctionComponent<Props> = (props) => {
  const moduleId = props.translatedModule.id;
  return (
    <OfferingsModule
      {...props}
      type="Specials"
      urlHashPrefix={`#${moduleId}-special-`}
    />
  );
};

export default SpecialsModule;
