import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { style } from "typestyle";
import { LineStyle, LineWeight } from "../types/index.js";
import { toBase64 } from "../utils/utils.js";

interface Props {
  lineWeight: LineWeight;
  lineStyle: LineStyle;
  position: "left" | "right" | "full";
  color: string;
}

const SeparatorLine: FunctionComponent<Props> = ({
  lineStyle,
  lineWeight,
  color,
  position,
}) => {
  const waveBackgroundImage =
    lineStyle !== "wavy"
      ? undefined
      : `url('data:image/svg+xml;base64,${getWave(color, lineWeight)}')`;

  return (
    <div
      className={ClassNames(
        `SeparatorModule__Line SeparatorModule__Line--${position}`,
        `SeparatorModule__Line--weight-${lineWeight}`,
        `SeparatorModule__Line--style-${lineStyle}`,
        style({ backgroundImage: waveBackgroundImage }),
      )}
    />
  );
};

const widthMap: { [key in LineWeight]: number } = { light: 1, strong: 2 };

const getWave = (stroke: string, lineWeight: LineWeight) =>
  toBase64(`
    <svg width="2310.6" height="44.54" version="1.1" viewBox="335 191 2396 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M 337 201 A 157 120 0 0 0 451 201 A 157 120 0 0 1 565 201 A 157 120 0 0 0 679 201 A 157 120 0 0 1 793 201 A 157 120 0 0 0 907 201 A 157 120 0 0 1 1021 201 A 157 120 0 0 0 1135 201 A 157 120 0 0 1 1249 201 A 157 120 0 0 0 1363 201 A 157 120 0 0 1 1477 201 A 157 120 0 0 0 1591 201 A 157 120 0 0 1 1705 201 A 157 120 0 0 0 1819 201 A 157 120 0 0 1 1933 201 A 157 120 0 0 0 2047 201 A 157 120 0 0 1 2161 201 A 157 120 0 0 0 2275 201 A 157 120 0 0 1 2389 201 A 157 120 0 0 0 2503 201 A 157 120 0 0 1 2617 201 A 157 120 0 0 0 2731 201" fill="none" stroke="${stroke}" stroke-linecap="square" stroke-width="${widthMap[lineWeight]}" ></path></svg>`);

export default SeparatorLine;
