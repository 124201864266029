import { AllActions } from "../actions/index.js";
import { Room } from "../types/index.js";

const reducer = (state: Room[] = [], action: AllActions) => {
  switch (action.type) {
    case "GET_ROOMS_SUCCESS":
      return action.rooms;

    default:
      return state;
  }
};

export default reducer;
