import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { PartnerLogoSize, TranslatedPartnerLogo } from "../types/index.js";
import PartnerLogo from "./PartnerLogo.js";

interface Props {
  className?: string;
  partnerLogos: TranslatedPartnerLogo[];
  logoSize: PartnerLogoSize;
}

const PartnerLogos: FunctionComponent<Props> = ({
  partnerLogos,
  logoSize,
  className,
}) => {
  return (
    <div className={ClassNames("PartnerLogos", className)}>
      <div className="PartnerLogos__Inner">
        {partnerLogos.map((partnerLogo) => (
          <PartnerLogo
            key={partnerLogo.id}
            partnerLogo={partnerLogo}
            size={logoSize}
          />
        ))}
      </div>
    </div>
  );
};

export default PartnerLogos;
