import ClassNames from "classnames";
import { ChangeEvent, FunctionComponent, ReactNode } from "react";
import Icon from "./Icon.js";

interface Props {
  className?: string;
  htmlId: string;
  text?: string;
  onChange: (value: boolean) => void;
  checkedStatus: boolean;
  children?: ReactNode;
}

const getCheckedStatus = (event: ChangeEvent<HTMLInputElement>) =>
  event.target.checked;

const Checkbox: FunctionComponent<Props> = ({
  htmlId,
  text,
  onChange,
  checkedStatus,
  className,
  children,
}) => {
  return (
    <div className={ClassNames("Form__Field Form__Field--checkbox", className)}>
      <label htmlFor={htmlId}>
        <input
          className="Form__Field__Checkbox"
          type="checkbox"
          checked={checkedStatus}
          id={htmlId}
          onChange={(e) => onChange(getCheckedStatus(e))}
        />
        <Icon
          className="Form__Field__CheckboxIcon Form__Field__CheckboxIcon--checked"
          glyph="check-box-checked"
        />
        <Icon className="Form__Field__CheckboxIcon" glyph="check-box" /> {text}
      </label>
      {children}
    </div>
  );
};

export default Checkbox;
