import { Component } from "react";

import AccommodationFeaturesModule from "../components/Modules/AccommodationFeaturesModule.js";
import ACSmartVoucherModule from "../components/Modules/ACSmartVoucherModule.js";
import ArriveInSouthTyrolModule from "../components/Modules/ArriveInSouthTyrolModule.js";
import BookingModule from "../components/Modules/BookingModule.js";
import ButtonModule from "../components/Modules/ButtonModule.js";
import EasiFastCheckInModule from "../components/Modules/EasiFastCheckInModule.js";
import EasiPayModule from "../components/Modules/EasiPayModule.js";
import EnquiryModule from "../components/Modules/EnquiryModule.js";
import FooterModule from "../components/Modules/FooterModule.js";
import GastroPoolInsuranceModule from "../components/Modules/GastroPoolInsuranceModule.js";
import GetavoModule from "../components/Modules/GetavoModule.js";
import Google360VirtualTourModule from "../components/Modules/Google360VirtualTourModule.js";
import GuestnetWidgetModule from "../components/Modules/GuestnetWidgetModule.js";
import HeaderModule from "../components/Modules/HeaderModule.js";
import HighlightsModule from "../components/Modules/HighlightsModule.js";
import HogastGastropoolPayModule from "../components/Modules/HogastGastropoolPayModule.js";
import HogastGastropoolSmartPayModule from "../components/Modules/HogastGastropoolSmartPayModule.js";
import HTMLModule from "../components/Modules/HTMLModule.js";
import ImageGalleryModule from "../components/Modules/ImageGalleryModule.js";
import ImagesModule from "../components/Modules/ImagesModule.js";
import MapModule from "../components/Modules/MapModule.js";
import MTSToursModule from "../components/Modules/MTSToursModule.js";
import NewsletterModule from "../components/Modules/NewsletterModule.js";
import NotFoundTextModule from "../components/Modules/NotFoundTextModule.js";
import PeerTvModule from "../components/Modules/PeerTvModule.js";
import PopUpModule from "../components/Modules/PopUpModule.js";
import PortalModule from "../components/Modules/PortalModule.js";
import PriceModule from "../components/Modules/PriceModule.js";
import QuestionAndAnswerModule from "../components/Modules/QuestionAndAnswerModule.js";
import QuestionsAndAnswersModule from "../components/Modules/QuestionsAndAnswersModule.js";
import QuickEnquiryModule from "../components/Modules/QuickEnquiryModule.js";
import QuoteModule from "../components/Modules/QuoteModule.js";
import RoomsModule from "../components/Modules/RoomsModule.js";
import SeparatorModule from "../components/Modules/SeparatorModule.js";
import SpecialsModule from "../components/Modules/SpecialsModule.js";
import TextModule from "../components/Modules/TextModule.js";
import VideoModule from "../components/Modules/VideoModule.js";
import WeatherModule from "../components/Modules/WeatherModule.js";
import WeatherWebcamModule from "../components/Modules/WeatherWebcamModule.js";
import WebcamModule from "../components/Modules/WebcamModule.js";
import { AllModulesByType, SystemPageModuleType } from "../types/index.js";

export const mostModulesByType: Omit<AllModulesByType, SystemPageModuleType> = {
  HeaderModule,
  FooterModule,
  ButtonModule,
  BookingModule,
  PortalModule,
  EnquiryModule,
  QuickEnquiryModule,
  HighlightsModule,
  HighlightModule: Component, // Dummy, as it can't be imported
  ImagesModule,
  ImageModule: Component, // Dummy, as it can't be imported
  OverlayModule: Component, // Dummy, as it can't be imported
  ImageGalleryModule,
  MapModule,
  PriceModule,
  RoomsModule,
  SpecialsModule,
  TextModule,
  VideoModule,
  EasiPayModule,
  EasiFastCheckInModule,
  HTMLModule,
  SeparatorModule,
  NotFoundTextModule,
  MTSToursModule,
  WeatherWebcamModule,
  WeatherModule,
  WebcamModule,
  Google360VirtualTourModule,
  EmbeddedGoogleMapsModule: Component, // Dummy, as it can't be imported
  GastroPoolInsuranceModule,
  PopUpModule,
  NewsletterModule,
  PeerTvModule,
  ACSmartVoucherModule,
  QuestionsAndAnswersModule,
  QuestionAndAnswerModule,
  GuestnetWidgetModule,
  HogastGastropoolPayModule,
  HogastGastropoolSmartPayModule,
  QuoteModule,
  AccommodationFeaturesModule,
  ArriveInSouthTyrolModule,
  GetavoModule,
};
