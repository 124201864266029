import { LoadStatus, ThunkAction } from "../types/index.js";
import { insertScriptIntoHead } from "../utils/utils.js";

export type Action = { type: "LOAD_SCRIPT"; url: string; status: LoadStatus };

export const injectScript =
  (url: string): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const loadState = getState().loadStates.scripts[url];
    if (loadState === "loaded" || loadState === "loading") {
      return;
    }
    dispatch(loadScript(url, "loading"));
    try {
      await insertScriptIntoHead(url, document);
      dispatch(loadScript(url, "loaded"));
    } catch (error) {
      dispatch(loadScript(url, "unloaded"));
    }
  };

export const loadScript = (url: string, status: LoadStatus): Action => ({
  type: "LOAD_SCRIPT",
  url,
  status,
});
