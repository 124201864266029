import { Reducer } from "redux";
import { AllActions } from "../actions/index.js";
import { Accommodation, AccommodationState, Language } from "../types/index.js";

const initialState: AccommodationState = {};

const getAccommodationSuccess = (
  state: AccommodationState,
  languageId: Language,
  accommodation: Accommodation,
): AccommodationState => {
  return { ...state, [languageId]: accommodation };
};

const reducer: Reducer<AccommodationState, AllActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case "GET_ACCOMMODATION_SUCCESS":
      return getAccommodationSuccess(
        state,
        action.languageId,
        action.accommodation,
      );

    default:
      return state;
  }
};

export default reducer;
