import ClassNames from "classnames";
import { FunctionComponent, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Language, MainMenuItem, PageState } from "../types/index.js";
import MainMenuLevelCollapsible from "./MainMenuLevelCollapsible.js";

interface Props {
  menuItems: MainMenuItem[];
  parentMenuItem?: MainMenuItem;
  isPreview: boolean;
  pages: PageState;
  languageId: Language;
  onMenuItemClick: () => void;
  menuLevel: number;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  collapseLevel: number;
  isOpen?: boolean;
  classNames: ClassNamesProp;
}

const MainMenuListCollapsible: FunctionComponent<Props> = ({
  menuItems,
  parentMenuItem,
  isPreview,
  languageId,
  pages,
  onMenuItemClick,
  menuLevel,
  activePagePath,
  fallbackLanguageId,
  collapseLevel,
  isOpen: isOpenProp = false,
  classNames,
}) => {
  const ref = useRef<HTMLUListElement>(null);

  const isOpen = isOpenProp && menuLevel === collapseLevel + 1;

  const repeatParentItem = Boolean(
    parentMenuItem &&
      !parentMenuItem.isFolder &&
      menuLevel === collapseLevel + 1,
  );

  const combinedMenuItems = [
    ...(parentMenuItem && repeatParentItem ? [parentMenuItem] : []),
    ...menuItems,
  ];

  const list = !menuItems.length ? null : (
    <CSSTransition in={isOpen} classNames="" timeout={{ enter: 500, exit: 0 }}>
      {(status) => (
        <ul
          ref={ref}
          className={ClassNames(classNames.list, {
            [classNames.listTransition]:
              menuLevel === collapseLevel + 1 &&
              (status === "entering" || status === "exiting"),
            [classNames.listExit]:
              menuLevel === collapseLevel + 1 &&
              (status === "exiting" || status === "exited"),
            [classNames.listLevel0]: menuLevel === 0,
            [classNames.listLevel1]: menuLevel === 1,
            [classNames.listLevel2up]: menuLevel >= 2,
          })}
          style={{
            height:
              status === "entering" || status === "exiting"
                ? ref.current?.scrollHeight + "px"
                : undefined,
          }}
        >
          {combinedMenuItems.map((currentMenuItem, index) => (
            <MainMenuLevelCollapsible
              key={currentMenuItem.id}
              currentMenuItem={currentMenuItem}
              isPreview={isPreview}
              languageId={languageId}
              parentId={currentMenuItem.id}
              pages={pages}
              onMenuItemClick={onMenuItemClick}
              menuLevel={menuLevel}
              activePagePath={activePagePath}
              fallbackLanguageId={fallbackLanguageId}
              collapseLevel={collapseLevel}
              isRepeatedMenuItem={repeatParentItem && index === 0}
              classNames={classNames}
            />
          ))}
        </ul>
      )}
    </CSSTransition>
  );

  return menuLevel === 0 ? <div className={classNames.main}>{list}</div> : list;
};

export interface ClassNamesProp {
  main: string;
  list: string;
  listTransition: string;
  listExit: string;
  listLevel0: string;
  listLevel1: string;
  listLevel2up: string;
  item: string;
  itemWrap: string;
  itemActive: string;
  itemLevel0: string;
  itemLevel1: string;
  itemLevel2up: string;
  listItem: string;
  listItemLevel0: string;
  listItemLevel1: string;
  listItemLevel2up: string;
  text: string;
  expandButton: string;
  expandIcon: string;
  expandIconOpen: string;
}

export const defaultClassNames: ClassNamesProp = {
  main: "MainMenu",
  list: "MainMenu__List MainMenu__List--collapse",
  listTransition: "MainMenu__List--collapse-transition",
  listExit: "MainMenu__List--collapse-exit",
  listLevel0: "MainMenu__ListLevel0",
  listLevel1: "MainMenu__ListLevel1",
  listLevel2up: "MainMenu__ListLevel2up",
  item: "MainMenu__Item",
  itemWrap: "MainMenu__ItemWrap",
  itemActive: "MainMenu__Item--active",
  itemLevel0: "MainMenu__ItemLevel0",
  itemLevel1: "MainMenu__ItemLevel1",
  itemLevel2up: "MainMenu__ItemLevel2up",
  listItem: "MainMenu__ListItem",
  listItemLevel0: "MainMenu__ListItemLevel0",
  listItemLevel1: "MainMenu__ListItemLevel1",
  listItemLevel2up: "MainMenu__ListItemLevel2up",
  text: "MainMenu__Item__Text",
  expandButton: "MainMenu__ExpandButton",
  expandIcon: "MainMenu__ExpandIcon",
  expandIconOpen: "MainMenu__ExpandIcon--open",
};

export const verticalMenuClassNames: ClassNamesProp = {
  main: "VerticalMainMenu",
  list: "VerticalMainMenu__List",
  listTransition: "VerticalMainMenu__List--transition",
  listExit: "VerticalMainMenu__List--exit",
  listLevel0: "VerticalMainMenu__List--level0",
  listLevel1: "VerticalMainMenu__List--level1",
  listLevel2up: "VerticalMainMenu__List--level2up",
  item: "VerticalMainMenu__Item",
  itemWrap: "VerticalMainMenu__ItemWrap",
  itemActive: "VerticalMainMenu__Item--active",
  itemLevel0: "VerticalMainMenu__Item--level0",
  itemLevel1: "VerticalMainMenu__Item--level1",
  itemLevel2up: "VerticalMainMenu__Item--level2up",
  listItem: "VerticalMainMenu__ListItem",
  listItemLevel0: "VerticalMainMenu__ListItem--level0",
  listItemLevel1: "VerticalMainMenu__ListItem--level1",
  listItemLevel2up: "VerticalMainMenu__ListItem--level2up",
  text: "VerticalMainMenu__Text",
  expandButton: "VerticalMainMenu__ExpandButton",
  expandIcon: "VerticalMainMenu__ExpandIcon",
  expandIconOpen: "VerticalMainMenu__ExpandIcon--open",
};

export default MainMenuListCollapsible;
