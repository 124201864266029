import { FunctionComponent, memo } from "react";
import { LazyImageProps } from "../types/index.js";

const LazyImage: FunctionComponent<LazyImageProps> = ({
  src,
  alt,
  className,
  sizes,
  srcSet,
  width,
  height,
  loading,
}) => (
  <img
    className={className}
    src={src}
    srcSet={srcSet}
    loading={loading}
    sizes={sizes}
    width={width}
    height={height}
    alt={alt ?? ""}
  />
);

export default memo(LazyImage);
